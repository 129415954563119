import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, colorTokens } from "../../theme";
import { sidebarContext } from "./SidebarContext";
import { useAuth0 } from "@auth0/auth0-react";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Topbar = () => {
  const theme = useTheme();
  const colors = colorTokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { isToggled, setToggled } = useContext(sidebarContext);
  const { logout, isAuthenticated, isLoading } = useAuth0();

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        p="2"
        backgroundColor={theme.palette.background}
      >
        <Box display="flex">
          <IconButton onClick={() => setToggled(!isToggled)}>
            <MenuOutlinedIcon />
          </IconButton>
        </Box>

        <Box display="flex">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>

          <IconButton onClick={logout}>
            <LogoutOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <Box textAlign="center" mt="0px">
        <hr color={theme.palette.secondary.main}></hr>
      </Box>
    </div>
  );
};

export default Topbar;
