import {
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { MenuItem, Typography } from "@mui/material";
import csvExport from "../utilFunctions/csvExport";
import excelExport from "../utilFunctions/excelExport";

const customToolbarMaker = (jsonData, fileName) => {
  const functionResultant = (props) => {
    return (
      <GridToolbarContainer {...props}>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <ExportButton jsonData={jsonData} fileName={fileName} />
      </GridToolbarContainer>
    );
  };

  return functionResultant;
};

const ExportButton = (props) => {
  return (
    <GridToolbarExportContainer>
      <ExportMenuItem
        menuName="Download Excel"
        exportFun={excelExport}
        jsonData={props.jsonData}
        fileName={props.fileName}
      />
      <ExportMenuItem
        menuName="Download CSV"
        exportFun={csvExport}
        jsonData={props.jsonData}
        fileName={props.fileName}
      />
    </GridToolbarExportContainer>
  );
};

const ExportMenuItem = (props) => {
  const { menuName, exportFun, jsonData, fileName, hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        exportFun(jsonData, fileName);
        hideMenu?.();
      }}
    >
      <Typography>{menuName}</Typography>
    </MenuItem>
  );
};

export default customToolbarMaker;
