import Header from "../../global/layoutComponents/Header";
import { Box, useTheme, Typography, Fab, Button } from "@mui/material";

import { SelectForm } from "../../global/inputComponents/SelectForm";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { logoutIf401 } from "../../global/utilFunctions/logoutIf400";

import axios from "axios";

import { apiGet } from "../../global/utilFunctions/apiRequests";

import CloudIcon from "@mui/icons-material/Cloud";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

import UploadBox from "../../global/boxComponents/UploadBox";
import StatBoxCircle from "../../global/boxComponents/StatBoxCircle";
import StatBox from "../../global/boxComponents/StatBox";
import Table from "../../global/tableComponents/Table";

const UploadPage = ({ key, docType, extensionFile, uploadTitle }) => {
	const theme = useTheme();
	const { logout } = useAuth0();
	const [fileStatusTable, setFileStatusTable] = useState(null);
	const [count, setCount] = useState(1);
	const [intervalTrigger, setIntervalTrigger] = useState(1);
	const accessToken = useSelector((state) => state.auth0.accessToken);

	const getProcessFileStatus = (apiToken, docType, logout) => {
		const datetime = new Date().toISOString().slice(0, 10);

		console.log(datetime);
		try {
			const token = apiToken;
			if (token != null) {
				const tableData = axios
					.post(
						process.env.REACT_APP_DASHBOARD_API + "/filestatus",
						{
							datetime: datetime,
							docType: docType,
						},
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then((res) => {
						return res.data;
					})
					.catch((err) => {
						logoutIf401(err, logout, token);
					});

				return tableData;
			}
		} catch (err) {
			console.log(err);
		}
		return null;
	};

	useEffect(() => {
		setInterval(() => {
			setIntervalTrigger((v) => v + 1);
		}, 5000);
	}, [key]);

	useEffect(() => {
		console.log(count);
		if (count < 15) {
			setCount((v) => v + 1);
			getProcessFileStatus(accessToken, docType, logout).then((r) => {
				setFileStatusTable(r);
			});
		}
	}, [key, accessToken, intervalTrigger]);

	const columnsTable = [
		{ field: "id", headerName: "ID", flex: 1, hide: true },
		{ field: "created_by", headerName: "Usuário", flex: 1 },
		{ field: "size", headerName: "Tamanho", flex: 1 },
		{ field: "real_name", headerName: "Nome do arquivo", flex: 1 },
		{ field: "doc_type", headerName: "Tipo do documento", flex: 1 },
		{ field: "status", headerName: "Status", flex: 1 },
		{ field: "error", headerName: "Mensagem", flex: 1 },
	];
	const percNumberError = (arr) => {
		if (arr.data.length != 0) {
			return arr.data.filter((e) => e.status == "error").length / arr.data.length;
		} else {
			return 0;
		}
	};

	const percNumberSuccess = (arr) => {
		if (arr.data.length != 0) {
			return arr.data.filter((e) => e.status == "success").length / arr.data.length;
		} else {
			return 0;
		}
	};

	return (
		<Box>
			<Box
				display="grid"
				gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
				gridAutoRows="140px"
				position
				gap="20px"
				marginBottom="20px"
			>
				{fileStatusTable && (
					<UploadBox
						span="3"
						docType={docType}
						arquivos={extensionFile}
						title={uploadTitle}
						clickFun={setCount}
						icon={<CloudIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>
				)}
				{fileStatusTable && (
					<StatBox
						span="3"
						BigNumber={fileStatusTable.data.length}
						title="Total de arquivos recebidos hoje"
						percNumber=""
						icon={<UploadFileIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>
				)}
				{fileStatusTable && (
					<StatBoxCircle
						span="3"
						BigNumber={fileStatusTable.data.filter((e) => e.status == "error").length}
						title="Arquivos com Erro"
						progressNumber={percNumberError(fileStatusTable)}
						percNumber={percNumberError(fileStatusTable)}
						icon={<ClearIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>
				)}

				{fileStatusTable && (
					<StatBoxCircle
						span="3"
						BigNumber={fileStatusTable.data.filter((e) => e.status == "success").length}
						title="Arquivos processados"
						progressNumber={percNumberSuccess(fileStatusTable)}
						percNumber={percNumberSuccess(fileStatusTable)}
						icon={<CheckIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>
				)}
			</Box>
			{fileStatusTable && (
				<Table
					checkBoxSelection={false}
					jsonData={fileStatusTable.data}
					columns={columnsTable}
					fileName="fileNameTeste"
				/>
			)}
		</Box>
	);
};

const GestorLiquidacao = () => {
	const theme = useTheme();
	const { logout } = useAuth0();
	const accessToken = useSelector((state) => state.auth0.accessToken);
	const [fundName, setFundName] = useState("");
	const [fundOptions, setFundOptions] = useState([]);

	console.log(new Date());

	useEffect(() => {
		if (accessToken !== "") {
			apiGet("/availableFunds", accessToken, logout)
				.then((value) => {
					value.map((e) => {
						delete Object.assign(e, { key: e.dbname })["dbname"];
						delete Object.assign(e, { value: e.fundname })["fundname"];
					});
					setFundOptions(value);
					return 1;
				})
				.catch((err) => console.error(err));
		}
	}, [accessToken, logout]);

	var content;
	switch (fundName) {
		case "xama_prod":
		case "xama_dev":
			content = (
				<UploadPage
					key="muveCreditosLiquidados"
					docType="muve_creditos_liquidados"
					extensionFile=".xlsx"
					uploadTitle="Upload liquidados"
				/>
			);
			break;
		default:
			content = (
				<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
					<Header title="Não há liquidação configurada para este cliente." subtitle="" />
				</Box>
			);
	}
	return (
		<Box m="20px">
			<Box
				display="grid"
				gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
				gridAutoRows="0"
				position
				gap="20px"
				mt="60px"
				marginBottom="40px"
			>
				<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<Header title="Liquidação de Créditos" subtitle="Upload de créditos liquidados" />
					</Box>
				</Box>

				<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
					<SelectForm label="Fundos" varValue={fundName} setFun={setFundName} optionArray={fundOptions} />
				</Box>
			</Box>
			<>{content}</>
		</Box>
	);
};

export default GestorLiquidacao;
