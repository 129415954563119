import { Box, useTheme, Typography, Fab } from "@mui/material";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { apiPost } from "../../global/utilFunctions/apiRequests";

import ConfirmationFab from "../buttons/ConfirmationFab";

const StatBoxCheckoutXama = ({
  span,
  title,
  subtitle,
  refresFun,
  confirmButtonDisabled,
  ops,
  checkoutDate,
}) => {
  const theme = useTheme();
  const accessToken = useSelector((state) => state.auth0.accessToken);
  const user = useSelector((state) => state.auth0.user);
  const { logout } = useAuth0();

  return (
    <Box
      gridColumn={`span ${span}`}
      backgroundColor={theme.palette.primary.light}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ boxShadow: 1, minWidth: 300 }}
    >
      <Box width="100%" m="0 30px">
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography
              variant="h3"
              fontWeight="bold"
              sx={{ color: theme.palette.neutral.light }}
            >
              {title}
            </Typography>
            <Typography
              variant="h3"
              fontWeight="bold"
              sx={{ color: theme.palette.secondary.main }}
            >
              {subtitle}
            </Typography>
          </Box>

          <Box display="flex" sx={{ "& > :not(style)": { m: 0, mr: 0.2 } }}>
            <ConfirmationFab
              disabled={confirmButtonDisabled}
              icon={<ShoppingCartCheckoutIcon />}
              confirmMsg="Confirma a validade dos dados deste dia?"
              confirmFun={() => {
                const resp = apiPost(
                  "/romaneioCheckoutSubmmit",
                  {
                    checkoutDate: checkoutDate,
                    userInfo: {
                      email: user.email,
                    },
                    ops: ops,
                  },
                  accessToken,
                  logout
                );

                const timeout = setTimeout(() => {
                  refresFun((e) => !e);
                }, 3000);
                const timeout2 = setTimeout(() => {
                  refresFun((e) => !e);
                }, 2000);
                const timeout3 = setTimeout(() => {
                  refresFun((e) => !e);
                }, 1000);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StatBoxCheckoutXama;
