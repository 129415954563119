import Header from "../../global/layoutComponents/Header";
import { Box, useTheme, Typography, Fab } from "@mui/material";
import { SelectForm } from "../../global/inputComponents/SelectForm";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { apiGet } from "../../global/utilFunctions/apiRequests";

import BaixaRondologViewer from "../../global/gestorBaixaComponent/BaixaRondologViewer";
import BaixaXamaViewer from "../../global/gestorBaixaComponent/BaixaXamaViewer";
import BaixaTesteViewer from "../../global/gestorBaixaComponent/BaixaTesteViewer";

const GestorBaixaResolution = () => {
  const theme = useTheme();
  const { logout } = useAuth0();
  const accessToken = useSelector((state) => state.auth0.accessToken);

  const [fundName, setFundName] = useState("");
  const [fundOptions, setFundOptions] = useState([]);

  console.log(new Date());
  useEffect(() => {
    if (accessToken != "") {
      apiGet("/availableFunds", accessToken, logout)
        .then((value) => {
          value.map((e) => {
            delete Object.assign(e, { key: e.dbname })["dbname"];
            delete Object.assign(e, { value: e.fundname })["fundname"];
          });
          setFundOptions(value);
        })
        .catch((err) => console.error(err));
    }
  }, [accessToken]);

  switch (fundName) {
    case "xama_prod":
    case "xama_dev":
      var content = (
        <BaixaXamaViewer
          fundName={fundName}
          logout={logout}
          accessToken={accessToken}
        />
      );
      break;
    case "rondolog_prod":
    case "rondolog_dev":
      var content = (
        <BaixaRondologViewer
          fundName={fundName}
          logout={logout}
          accessToken={accessToken}
        />
      );
      break;
    case "teste":
      var content = (
        <BaixaTesteViewer
          fundName={fundName}
          logout={logout}
          accessToken={accessToken}
        />
      );

      break;
    default:
      var content = (
        <Box m="20px">
          <Header title="Selecione um Fundo" subtitle="" />
        </Box>
      );
  }

  return (
    <Box m="20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
        gridAutoRows="0"
        position
        gap="20px"
        mt="60px"
        marginBottom="40px"
      >
        <Box
          gridColumn={`span 6`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Header
              title="BAIXA CREDITOS"
              subtitle="Resolver pendências dos checkouts"
            />
          </Box>
        </Box>

        <Box
          gridColumn={`span 6`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SelectForm
            label="Fundos"
            varValue={fundName}
            setFun={setFundName}
            optionArray={fundOptions}
          />
        </Box>
      </Box>

      <>{content}</>
    </Box>
  );
};

export default GestorBaixaResolution;
