export const logoutIf401 = (err, logoutFUN, token) => {
  if (err.response) {
    if (err.response.status == "401") {
      if ((token != null) & (token != undefined) & (token != "")) {
        logoutFUN();
      }
    }
  } else {
    throw err;
  }
};
