import * as XLSX from "xlsx";
import downloadBlobFile from "./downloadBlobFile";

const csvExport = (jsonData, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(jsonData);
  const csv = XLSX.utils.sheet_to_csv(worksheet, { FS: ";" });
  const blob = new Blob([csv], { type: "text/plain;charset=UTF-8" });

  downloadBlobFile(blob, fileName + ".csv");
};

export default csvExport;
