import { Box, Typography, useTheme } from "@mui/material";
import { prettyNumber } from "../utilFunctions/mathUtils";
const StatBox = ({
  span,
  title,
  BigNumber,
  BigNumberType = "integer",
  icon,
  percNumber,
}) => {
  const theme = useTheme();

  return (
    <Box
      gridColumn={`span ${span}`}
      backgroundColor={theme.palette.primary.light}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ boxShadow: 1, minWidth: 300 }}
    >
      <Box width="100%" m="0 30px">
        <Box display="flex" justifyContent="space-between">
          <Box>
            {icon}
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: theme.palette.neutral.light }}
            >
              {prettyNumber(BigNumber, BigNumberType)}
            </Typography>
          </Box>
          {/* 
          <Box>
            <ProgressCircle progress={progressNumber} />
          </Box> */}
        </Box>

        <Box display="flex" justifyContent="space-between" mt="2px">
          <Typography
            variant="h5"
            sx={{ wordWrap: "break-word", color: theme.palette.secondary.main }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            fontStyle="italic"
            sx={{ color: theme.palette.secondary.main }}
          ></Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StatBox;
