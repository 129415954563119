import * as XLSX from "xlsx";

const excelExport = (jsonData, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(jsonData);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
  const finalName = fileName + ".xlsx";
  XLSX.writeFile(workbook, finalName, { compression: true });
};

export default excelExport;
