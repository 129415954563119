import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth0/Auth0ProviderWithHistory";
import ForceLogin from "./auth0/ForceLogin";
import { Provider } from "react-redux";
import store from "./global/redux/store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <ForceLogin>
        <Provider store={store}>
          <App />
        </Provider>
      </ForceLogin>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
  //</React.StrictMode>
);
