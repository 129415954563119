import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  useTheme,
} from "@mui/material";

export const SelectForm = (props) => {
  const { varValue, label, setFun, optionArray } = props;
  const theme = useTheme();
  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          "&.Mui-focused": {
            borderColor: theme.palette.neutral.light,
            color: theme.palette.neutral.light,
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        sx={{
          "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.neutral.light,
            },
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: theme.palette.primary.main,
              "& .MuiMenuItem-root": {
                padding: 2,
              },
            },
          },
        }}
        value={varValue}
        label={label}
        onChange={(e) => {
          setFun(e.target.value);
        }}
      >
        {optionArray.map((option) => {
          return (
            <MenuItem key={option?.key} value={option?.key}>
              {option?.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
