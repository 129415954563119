import Header from "../../global/layoutComponents/Header";
import { Box, useTheme, Typography, Fab, Button } from "@mui/material";

import { SelectForm } from "../../global/inputComponents/SelectForm";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { apiGet } from "../../global/utilFunctions/apiRequests";

import DocApprovalRondologViewer from "../../global/docApprovalComponent/DocApprovalRondologViewer";
import DocApprovalXamaViewer from "../../global/docApprovalComponent/DocApprovalXamaViewer";
import DocApprovalDefaultViewer from "../../global/docApprovalComponent/DocApprovalDefaultViewer";

const GestorDocApproval = () => {
	const theme = useTheme();
	const { logout } = useAuth0();
	const accessToken = useSelector((state) => state.auth0.accessToken);
	const [fundName, setFundName] = useState("");
	const [fundOptions, setFundOptions] = useState([]);

	console.log(new Date());

	useEffect(() => {
		if (accessToken !== "") {
			apiGet("/availableFunds", accessToken, logout)
				.then((value) => {
					value.map((e) => {
						delete Object.assign(e, { key: e.dbname })["dbname"];
						delete Object.assign(e, { value: e.fundname })["fundname"];
					});
					setFundOptions(value);
					return 1;
				})
				.catch((err) => console.error(err));
		}
	}, [accessToken, logout]);

	var content;
	switch (fundName) {
		case "xama_prod":
		case "xama_dev":
			content = (
				<DocApprovalXamaViewer
					fundName={fundName}
					setFundName={setFundName}
					logout={logout}
					accessToken={accessToken}
					fundOptions={fundOptions}
					theme={theme}
				/>
			);
			break;
		case "rondolog_prod":
		case "rondolog_dev":
			content = (
				<DocApprovalRondologViewer
					fundName={fundName}
					setFundName={setFundName}
					logout={logout}
					accessToken={accessToken}
					fundOptions={fundOptions}
					theme={theme}
				/>
			);
			break;
		case "teste":
			content = (
				<DocApprovalDefaultViewer
					fundName={fundName}
					logout={logout}
					accessToken={accessToken}
					setFundName={setFundName}
					fundOptions={fundOptions}
					theme={theme}
				/>
			);
			break;
		default:
			content = (
				<DocApprovalDefaultViewer
					fundName={fundName}
					logout={logout}
					accessToken={accessToken}
					setFundName={setFundName}
					fundOptions={fundOptions}
					theme={theme}
				/>
			);
	}
	return (
		<Box m="20px">
			<Box
				display="grid"
				gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
				gridAutoRows="0"
				position
				gap="20px"
				mt="60px"
				marginBottom="40px"
			>
				<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<Header title="Aprovação de Documentos" subtitle="Aprovar documentos dos cedentes" />
					</Box>
				</Box>

				<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
					<SelectForm label="Fundos" varValue={fundName} setFun={setFundName} optionArray={fundOptions} />
				</Box>
			</Box>
			<>{content}</>
		</Box>
	);
};

export default GestorDocApproval;
