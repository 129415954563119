import Header from "../../global/layoutComponents/Header";
import { CheckoutStatBox } from "../../global/boxComponents/CheckoutStatBox";
import { Box, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleIcon from "@mui/icons-material/People";
import { apiPost } from "../../global/utilFunctions/apiRequests";
import StatBoxCheckoutResolution from "../boxComponents/StatBoxCheckoutResolutions";

const CheckoutResolutionBoxesIfood = (props) => {
	const { array, dbName, refresFun, ops } = props;
	const theme = useTheme();

	console.log(array);
	return array.map((e) => {
		return (
			<Box key={`${e.date_to_checkout}${e.avg}${e.total_valor_face}`}>
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					gridAutoRows="140px"
					position
					gap="60px"
					marginBottom="2px"
				>
					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.ops}
							BigNumberType="asIs"
							title={`Operação`}
							icon={<LocalShippingIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.entregadores_unicos_cadastrados}
							BigNumberType="integer"
							title={`MB únicos`}
							icon={<PeopleIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.ticket_medio}
							BigNumberType="cents"
							title={`Ticket Médio`}
							icon={<LocalShippingIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.total_valor_face}
							BigNumberType="cents"
							title={`Valor de face acumulado`}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<StatBoxCheckoutResolution
							span="3"
							subtitle={"CHECKOUT"}
							refresFun={refresFun}
							wtsDisabled={(e.status == "confirmed") | ((e.status == "closed") | (e.status == "committed"))}
							docDisabled={(e.status == "requested") | ((e.status == "closed") | (e.status == "committed"))}
							insertDbDisabled={(e.status == "requested") | ((e.status == "confirmed") | (e.status == "committed"))}
							title={`${e.date_to_checkout.slice(0, 10)}`}
							dbName={dbName}
							filterDate={`${e.date_to_checkout.slice(0, 10)}`}
							ops={ops}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
				</Box>
			</Box>
		);
	});
};

const CheckoutResolutionBoxesMuve = (props) => {
	const { array, dbName, refresFun, ops } = props;
	const theme = useTheme();

	console.log(array);
	return array.map((e) => {
		return (
			<Box key={`${e.date_to_checkout}${e.avg}${e.total_valor_face}`}>
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					gridAutoRows="140px"
					position
					gap="60px"
					marginBottom="2px"
				>
					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.ops}
							BigNumberType="asIs"
							title={`Operação`}
							icon={<LocalShippingIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.entregadores_unicos_cadastrados}
							BigNumberType="integer"
							title={`MB únicos Cadastrados`}
							icon={<PeopleIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.ticket_medio}
							BigNumberType="cents"
							title={`Ticket Médio`}
							icon={<LocalShippingIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.total_valor_face}
							BigNumberType="cents"
							title={`Valor de face acumulado`}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<StatBoxCheckoutResolution
							span="3"
							subtitle={"CHECKOUT"}
							refresFun={refresFun}
							wtsDisabled={(e.status == "confirmed") | ((e.status == "closed") | (e.status == "committed"))}
							docDisabled={(e.status == "requested") | ((e.status == "closed") | (e.status == "committed"))}
							insertDbDisabled={(e.status == "requested") | ((e.status == "confirmed") | (e.status == "committed"))}
							title={`${e.date_to_checkout.slice(0, 10)}`}
							dbName={dbName}
							filterDate={`${e.date_to_checkout.slice(0, 10)}`}
							ops={ops}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
				</Box>
			</Box>
		);
	});
};

export default function CheckoutXptoViewer(props) {
	const theme = useTheme();

	const [firstSearch, setFirstSearch] = useState(false);
	const [checkoutListIFood, setCheckoutListIfood] = useState([]);
	const [checkoutListMuve, setCheckoutListMuve] = useState([]);
	const [refreshList, setRefreshList] = useState(true);

	useEffect(() => {
		if ((props.fundName != "") & (props.accessToken != "")) {
			apiPost(
				"/gestorCheckoutList",
				{
					dbName: props.fundName,
					ops: "ifood",
				},
				props.accessToken,
				props.logout
			)
				.then((value) => {
					setCheckoutListIfood(value);
					setFirstSearch(true);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [props.accessToken, props.fundName, refreshList, props.logout]);

	useEffect(() => {
		if ((props.fundName != "") & (props.accessToken != "")) {
			apiPost(
				"/gestorCheckoutList",
				{
					dbName: props.fundName,
					ops: "xpto",
				},
				props.accessToken,
				props.logout
			)
				.then((value) => {
					setCheckoutListMuve(value);
					setFirstSearch(true);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [props.accessToken, props.fundName, refreshList, props.logout]);

	useEffect(() => {
		setInterval(() => {
			setRefreshList((v) => !v);
		}, 20000);
	}, []);

	return (
		<>
			{/* IFOOD */}

			<>
				<Box mt="80px" marginBottom="30px">
					<CheckoutResolutionBoxesIfood
						array={checkoutListIFood}
						dbName={props.fundName}
						ops="ifood"
						refresFun={setRefreshList}
					/>
				</Box>

				{checkoutListIFood.length === 0 && props.fundName !== "" && firstSearch === true && (
					<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
						<Header title="Não há Checkouts de IFOOD para serem finalizados dos últimos 20 dias" subtitle="" />
					</Box>
				)}

				<Box>
					<hr
						style={{
							border: "dotted 2px",
							color: theme.palette.secondary.main,
							backgroundColor: theme.palette.primary.main,
						}}
					></hr>
				</Box>
			</>

			{/* LINHA */}
			<Box textAlign="center" mt="30px" marginBottom="30px">
				<hr
					style={{
						border: "dotted 2px",
						color: theme.palette.secondary.main,
						backgroundColor: theme.palette.primary.main,
					}}
				></hr>
			</Box>

			{/* MUVE */}

			<>
				<Box mt="80px" marginBottom="30px">
					<CheckoutResolutionBoxesMuve
						array={checkoutListMuve}
						dbName={props.fundName}
						refresFun={setRefreshList}
						ops="xpto"
					/>
				</Box>

				{checkoutListMuve.length === 0 && props.fundName !== "" && firstSearch === true && (
					<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
						<Header title="Não há Checkouts de xpto para serem finalizados dos últimos 20 dias" subtitle="" />
					</Box>
				)}

				<Box>
					<hr
						style={{
							border: "dotted 2px",
							color: theme.palette.secondary.main,
							backgroundColor: theme.palette.primary.main,
						}}
					></hr>
				</Box>
			</>
		</>
	);
}
