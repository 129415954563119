import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material";

export const colorTokens = (mode) => {
  if (mode === "dark") {
    const result = {
      azulPlanalto: {
        100: "#d7dce3",
        200: "#afb9c7",
        300: "#8696aa",
        400: "#5e738e",
        500: "#365072",
        600: "#2b405b",
        700: "#203044",
        800: "#16202e",
        900: "#0b1017",
      },

      amareloPlanalto: {
        100: "#f7f1df",
        200: "#efe3c0",
        300: "#e6d4a0",
        400: "#dec681",
        500: "#d6b861",
        600: "#ab934e",
        700: "#806e3a",
        800: "#564a27",
        900: "#2b2513",
      },

      grey: {
        100: "#e2e2e2",
        200: "#c5c5c5",
        300: "#a7a7a7",
        400: "#8a8a8a",
        500: "#6d6d6d",
        600: "#575757",
        700: "#414141",
        800: "#2c2c2c",
        900: "#161616",
      },

      success: {
        100: "#d5f5e7",
        200: "#abebd0",
        300: "#81e2b8",
        400: "#57d8a1",
        500: "#2dce89",
        600: "#24a56e",
        700: "#1b7c52",
        800: "#125237",
        900: "#09291b",
      },

      warning: {
        100: "#fee0d9",
        200: "#fdc1b3",
        300: "#fda18c",
        400: "#fc8266",
        500: "#fb6340",
        600: "#c94f33",
        700: "#973b26",
        800: "#64281a",
        900: "#32140d",
      },

      danger: {
        100: "#eecdd3",
        200: "#dd9aa8",
        300: "#cd687c",
        400: "#bc3551",
        500: "#ab0325",
        600: "#89021e",
        700: "#670216",
        800: "#44010f",
        900: "#220107",
      },
    };
    return result;
  } else {
    const result = {
      azulPlanalto: {
        100: "#0b1017",
        200: "#16202e",
        300: "#203044",
        400: "#2b405b",
        500: "#365072",
        600: "#5e738e",
        700: "#8696aa",
        800: "#afb9c7",
        900: "#d7dce3",
      },

      amareloPlanalto: {
        100: "#2b2513",
        200: "#564a27",
        300: "#806e3a",
        400: "#ab934e",
        500: "#d6b861",
        600: "#dec681",
        700: "#e6d4a0",
        800: "#efe3c0",
        900: "#f7f1df",
      },

      grey: {
        100: "#161616",
        200: "#2c2c2c",
        300: "#414141",
        400: "#575757",
        500: "#6d6d6d",
        600: "#8a8a8a",
        700: "#a7a7a7",
        800: "#c5c5c5",
        900: "#e2e2e2",
      },

      success: {
        100: "#09291b",
        200: "#125237",
        300: "#1b7c52",
        400: "#24a56e",
        500: "#2dce89",
        600: "#57d8a1",
        700: "#81e2b8",
        800: "#abebd0",
        900: "#d5f5e7",
      },

      warning: {
        100: "#32140d",
        200: "#64281a",
        300: "#973b26",
        400: "#c94f33",
        500: "#fb6340",
        600: "#fc8266",
        700: "#fda18c",
        800: "#fdc1b3",
        900: "#fee0d9",
      },

      danger: {
        100: "#220107",
        200: "#44010f",
        300: "#670216",
        400: "#89021e",
        500: "#ab0325",
        600: "#bc3551",
        700: "#cd687c",
        800: "#dd9aa8",
        900: "#eecdd3",
      },
    };
    return result;
  }
};

export const themeSettings = (mode) => {
  const colors = colorTokens(mode);

  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.azulPlanalto[500],
              dark: colors.azulPlanalto[700],
            },
            secondary: {
              main: colors.amareloPlanalto[500],
              dark: colors.amareloPlanalto[800],
            },
            neutral: {
              main: colors.grey[500],
              dark: colors.grey[700],
              mediumLight: colors.grey[300],
              light: colors.grey[100],
            },
            danger: {
              main: colors.danger[500],
            },
            background: {
              default: colors.azulPlanalto[500],
            },
          }
        : {
            primary: {
              main: colors.azulPlanalto[500],
              dark: colors.azulPlanalto[700],
            },
            secondary: {
              main: colors.grey[900],
              dark: colors.amareloPlanalto[800],
              light: colors.amareloPlanalto[300],
            },
            neutral: {
              main: colors.grey[500],
              dark: colors.grey[700],
              light: colors.grey[300],
            },
            danger: {
              main: colors.danger[300],
            },
            background: {
              default: colors.azulPlanalto[800],
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
