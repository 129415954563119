import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../global/layoutComponents/Loading";

const ForceLogin = (props) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  if (
    !isAuthenticated |
    (isAuthenticated == null) |
    (isAuthenticated == undefined)
  ) {
    if (!isLoading | (isLoading == null) | (isLoading == undefined)) {
      loginWithRedirect();
    }
    return <Loading />;
  } else {
    return <>{props.children}</>;
  }
};

export default ForceLogin;
