import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: {},
  accessToken: "",
  isAuthenticated: true,
};

const updateAccessToken = (state, action) => {
  state.accessToken = action.payload.accessToken;
};
const updateIsAuthenticated = (state, action) => {
  state.isAuthenticated = action.payload.isAuthenticated;
};

const updateUser = (state, action) => {
  state.user = action.payload.user;
};
export const auth0Slice = createSlice({
  name: "auth0",
  initialState: initialState,
  reducers: {
    updateUser,
    updateAccessToken,
    updateIsAuthenticated,
  },
});

export const auth0SliceActions = auth0Slice.actions;
