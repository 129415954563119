import {
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, confirmDisabled } = props;

  const theme = useTheme();

  const listSX = {
    backgroundColor: theme.palette.primary.main,
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog" sx={listSX}>
        {title}
      </DialogTitle>
      <DialogContent sx={listSX}>{children}</DialogContent>
      <DialogActions sx={listSX}>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          sx={{
            color: theme.palette.neutral.light,
            backgroundColor: theme.palette.danger.main,
          }}
        >
          Não!
        </Button>
        <Button
          variant="contained"
          disabled={confirmDisabled}
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          sx={{
            color: theme.palette.neutral.light,
            backgroundColor: theme.palette.primary.dark,
          }}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};
