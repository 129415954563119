import { createContext, useContext, useState } from "react";

export const sidebarContext = createContext();

export const SidebarContextProvider = ({ children }) => {
  const [isToggled, setToggled] = useState(false);

  return (
    <sidebarContext.Provider value={{ isToggled, setToggled }}>
      {children}
    </sidebarContext.Provider>
  );
};
