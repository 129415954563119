import React from "react";
import { useTheme } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  return (
    <div>
      <Backdrop
        sx={{
          color: "#365072",
          backgroundColor: "white",
          zIndex: 1500,
        }}
        open={true}
        onClick={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Loading;
