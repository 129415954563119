import {
  Box,
  Typography,
  useTheme,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, forwardRef } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Fab from "@mui/material/Fab";
import { useSelector } from "react-redux";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutIf401 } from "../utilFunctions/logoutIf400";
const uploadFile = async (file, docType, user, apiToken, logout) => {
  const datetime = new Date().toISOString().slice(0, 10);

  const fileInfotoPost = {
    userInfo: { email: user.email },
    fileInfo: {
      fileNameUUI: uuidv4(),
      size: file.size,
      datetime: datetime,
      fileName: file.name,
      fileType: file.type,
      docType: docType,
    },
  };
  try {
    const signedUrl = await axios
      .post(process.env.REACT_APP_DASHBOARD_API + "/s3upload", fileInfotoPost, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      })
      .catch((err) => {
        logoutIf401(err, logout, apiToken);
      });

    console.log(signedUrl);
    await axios
      .put(signedUrl.data.uploadPreSignedURL, file, {
        withCredentials: true,
        headers: { "Content-Type": file.Type },
      })
      .catch((err) => {
        logoutIf401(err, logout, apiToken);
      });

    await axios
      .post(
        process.env.REACT_APP_DASHBOARD_API + "/s3operator",
        fileInfotoPost,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      )
      .catch((err) => {
        logoutIf401(err, logout, apiToken);
      });

    return 0;
  } catch (error) {
    const p_error = new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log("Faild to upload " + file.name);
        reject(file.name);
      }, 2 * 1000);
    });

    return p_error;
  }
};

const failedFilesFinder = async (arrStatuses) => {
  var failedtmp = [];
  arrStatuses.map((e) => {
    return e.status == "fulfilled" ? null : failedtmp.push(e.reason);
  });

  return failedtmp;
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UploadBox = ({ span, docType, title, arquivos, icon, clickFun }) => {
  const theme = useTheme();
  const { logout } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [failedFiles, setFailedFiles] = useState(new Array());
  const [failedUpload, setFailedUpload] = useState(false);
  const accessToken = useSelector((state) => state.auth0.accessToken);
  const user = useSelector((state) => state.auth0.user);

  const handleClose = () => {
    setFailedUpload(false);
  };

  const handleButtonClick = () => {
    setUploadError(false);
    setSuccess(false);
    setFailedFiles(new Array());
  };

  const fileUploadHandler = async (inputList) => {
    setLoading(true);

    clickFun(1);
    const promises = Array.from(inputList.target.files).map(async (file) => {
      return uploadFile(file, docType, user, accessToken, logout);
    });
    const statusesPromise = await Promise.allSettled(promises).then(
      (status) => status
    );
    const failed = await failedFilesFinder(statusesPromise);

    if (failed.length != 0) {
      console.log("entrei no erro");
      setUploadError(true);
      setLoading(false);
      setFailedFiles(failed);
      setFailedUpload(true);
    } else {
      setSuccess(true);
      setLoading(false);
    }
  };

  const buttonSx = {
    ...(success && {
      position: "relative",
      bgcolor: theme.palette.secondary.main,
      "&:hover": {
        bgcolor: theme.palette.secondary.dark,
      },
    }),
  };

  const listSX = {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.light,
  };

  return (
    <Box
      gridColumn={`span ${span}`}
      backgroundColor={theme.palette.primary.light}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ boxShadow: 1, minWidth: 300 }}
    >
      <Box width="100%" m="0 30px">
        <Box display="flex" justifyContent="space-between">
          <Box>
            {icon}
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: theme.palette.neutral.light }}
            >
              Arquivos {arquivos}
            </Typography>
          </Box>

          <Box sx={{ m: 1, position: "relative" }}>
            <Fab
              aria-label="save"
              color={uploadError ? "danger" : "primary"}
              sx={buttonSx}
              onClick={handleButtonClick}
              component="label"
            >
              {success ? (
                <CheckIcon />
              ) : uploadError ? (
                <ClearIcon />
              ) : (
                <UploadIcon />
              )}
              <input
                type="file"
                accept={arquivos}
                hidden
                multiple
                onClick={(event) => {
                  event.target.value = null;
                }}
                onChange={fileUploadHandler}
              />
            </Fab>
            {loading && (
              <CircularProgress
                size={68}
                sx={{
                  color: theme.palette.secondary.main,
                  position: "absolute",
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" mt="2px">
          <Typography
            variant="h5"
            sx={{ wordWrap: "break-word", color: theme.palette.secondary.main }}
          >
            {title}
          </Typography>
        </Box>
      </Box>

      {failedFiles.length != 0 && (
        <Dialog
          open={failedUpload}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle variant="h4" fontWeight="bold" sx={listSX}>
            {"Upload falhou para os seguintes arquivos:"}
          </DialogTitle>
          <DialogContent sx={listSX}>
            <DialogContentText id="alert-dialog-slide-description">
              <List sx={listSX} aria-label="contacts">
                {failedFiles.map((value) => (
                  <ListItem key={value}>
                    <ListItemText fontWeight="bold" primary={`${value}`} />
                  </ListItem>
                ))}
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={listSX}>
            <Button
              onClick={handleClose}
              sx={{ color: theme.palette.neutral.light }}
            >
              Sair
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default UploadBox;
