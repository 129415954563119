import { Box, Typography, useTheme } from "@mui/material";
import { prettyNumber } from "../utilFunctions/mathUtils";

export const CheckoutStatBox = ({
  span,
  title,
  BigNumber,
  BigNumberType = "integer",
  icon,
}) => {
  const theme = useTheme();

  return (
    <Box
      gridColumn={`span ${span}`}
      backgroundColor={theme.palette.primary.light}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ boxShadow: 1, minWidth: 300 }}
    >
      <Box width="100%" m="0 30px">
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography
              variant="h3"
              fontWeight="bold"
              sx={{ color: theme.palette.neutral.light }}
            >
              {title}
            </Typography>
            <Typography
              variant="h3"
              fontWeight="bold"
              sx={{ color: theme.palette.secondary.main }}
            >
              {prettyNumber(BigNumber, BigNumberType)}
            </Typography>
          </Box>

          <Box display="flex">{icon}</Box>
        </Box>
      </Box>
    </Box>
  );
};
