import { Box, Typography, useTheme } from "@mui/material";
import Header from "../../global/layoutComponents/Header";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colorTokens } from "../../theme";

const FaqQuestion = ({ question, answer }) => {
	const theme = useTheme();
	const colors = colorTokens(theme.palette.mode);

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.neutral.dark }} />}
				sx={{
					backgroundColor: theme.palette.neutral.light,
				}}
			>
				<Typography color={theme.palette.neutral.dark} variant="h5" fontWeight="bold">
					{question}
				</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					backgroundColor: colors.grey[100],
					borderBlockColor: theme.palette.background,
				}}
			>
				<Typography color={theme.palette.neutral.dark} variant="h6">
					{answer}
				</Typography>
			</AccordionDetails>
		</Accordion>
	);
};

const Faq = () => {
	return (
		<Box m="20px">
			<Header title="FAQ" subtitle="Dúvidas frequentes" />
			<FaqQuestion
				question="Como funciona o processo?"
				answer="Primeiro é necessário realizar o upload das informações referentes ao romaneio. Então o usuário poderá realizar o Checkout do dia específico, averiguando os dados. Após isso é esperar que a equipe de gestão dará prosseguimento"
			/>
			<FaqQuestion
				question="Caso o Upload resulte em falha, o que faço?"
				answer="Neste caso entre em contato com a equipe de gestão. Muito provavelmente os dados estão fora do formato combinado, ou houve alguma regra de negócio não especificada, nestes casos será decidido em conjunto o que será feito."
			/>
		</Box>
	);
};

export default Faq;
