import { prettyNumber } from "./mathUtils";
export const createGraphData = (
  data,
  xField,
  yField,
  xLabel,
  yLabel,
  xAxisFormat = "data",
  yAxisFormat = "asIs",
  xHoverAxisFormat = "data",
  yHoverAxisFormat = "asIs",
  lineColor = "#365072",
  markerColor = "#001f3f"
) => {
  return {
    x: data?.map((item) => prettyNumber(item[xField], xAxisFormat)),
    y: data?.map((item) => Number(prettyNumber(item[yField], yAxisFormat))),
    hovertemplate: data?.map(
      (item) =>
        `${xLabel}: ${prettyNumber(
          item[xField],
          xHoverAxisFormat
        )}<br>${yLabel}: ${prettyNumber(
          item[yField],
          yHoverAxisFormat
        )}<extra></extra>`
    ),
    line: { color: lineColor },
    marker: { color: markerColor },
  };
};
