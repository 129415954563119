import Header from "../../global/layoutComponents/Header";
import { Box, useTheme } from "@mui/material";
import Table from "../../global/tableComponents/Table";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import StatBox from "../../global/boxComponents/StatBox";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutIf401 } from "../../global/utilFunctions/logoutIf400";
import PaidIcon from "@mui/icons-material/Paid";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { prettyNumber } from "../../global/utilFunctions/mathUtils";

import { apiPost } from "../../global/utilFunctions/apiRequests";

const getRomaneioSummary = (apiToken, logout, filterDate) => {
  try {
    const token = apiToken;
    if (token != null) {
      const data = axios
        .post(
          process.env.REACT_APP_DASHBOARD_API + "/romaneioDay",
          {
            filterDate: filterDate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          logoutIf401(err, logout, token);
        });

      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

const getRomaneioAgregado = (apiToken, logout, filterDate) => {
  try {
    const token = apiToken;
    if (token != null) {
      const data = axios
        .post(
          process.env.REACT_APP_DASHBOARD_API + "/romaneioagregadoday",
          {
            filterDate: filterDate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          logoutIf401(err, logout, token);
          return {};
        });

      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

const transformRomaneio = (romaneioSummary, date) => {
  const dateMaker = (shift) => {
    const today = new Date();
    today.setDate(today.getDate() + shift);
    return today.toISOString().slice(0, 10);
  };
  const nullDayMaker = (date) => {
    if (date == null) {
      return {
        totalValue: "0",
        date: dateMaker(0),
      };
    } else {
      return {
        totalValue: "0",
        date: date,
      };
    }
  };

  const completeDayMaker = (romaneioSummary, date) => {
    const elementFound = romaneioSummary.find((e) => e.date == date);

    if (elementFound === undefined) {
      return nullDayMaker(date);
    }
    return {
      totalValue: elementFound.total,
      date: elementFound.date,
    };
  };

  if (romaneioSummary == null) {
    return nullDayMaker(date);
  } else {
    const newRomaneioSummary = completeDayMaker(romaneioSummary, date);

    return newRomaneioSummary;
  }
};

const getRomaneioAgregadoDetailed = (apiToken, logout, payload) => {
  const datetime = new Date().toISOString().slice(0, 10);

  console.log(datetime);
  try {
    const token = apiToken;
    if (token != null) {
      const data = axios
        .post(
          process.env.REACT_APP_DASHBOARD_API + "/romaneioDetailedDay",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          logoutIf401(err, logout, token);
        });

      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

const RondologDataVerification = () => {
  const theme = useTheme();
  const datetime = new Date().toISOString().slice(0, 10);
  const { logout } = useAuth0();
  const accessToken = useSelector((state) => state.auth0.accessToken);
  const [agregadoDailyContent, setAgregadoDailyContent] = useState(null);
  const [agregadoDetailedContent, setAgregadoDetailedContent] = useState(null);
  const [agregadoSelected, setAgregado] = useState(null);
  const [datePicked, setDatePicker] = useState(dayjs(datetime));
  const [romaneioSummary, setRomaneioSummary] = useState(null);
  const [cteAvailable, setCteAvailable] = useState({ valor_disponivel_cte: 0 });
  const newRomaneioSummary = transformRomaneio(
    romaneioSummary,
    datePicked.format("YYYY-MM-DD")
  );

  const columnsAgregadoDaily = [
    { field: "id", headerName: "ID", flex: 1, hide: true },
    { field: "agregado_cnpj_cpf", headerName: "CNPJ/CPF", flex: 1 },
    {
      field: "agregado_razao_social",
      headerName: "Nome/Razão Social",
      flex: 1,
    },
    {
      field: "soma_cents",
      headerName: "Total",
      flex: 1,
      valueFormatter: (params) => prettyNumber(params?.value, "cents"),
    },
    { field: "data_manifesto", headerName: "Data Manifesto", flex: 1 },
  ];

  const columnsAgregadoDetailed = [
    { field: "id", headerName: "ID", flex: 1, hide: true },
    { field: "data_manifesto", headerName: "Data Romaneio", flex: 1 },
    { field: "id_manifesto", headerName: "N Manifesto", flex: 1 },
    { field: "agregado_cnpj_cpf", headerName: "CNPJ/CPF", flex: 1 },
    {
      field: "agregado_razao_social",
      headerName: "Nome/Razão Social",
      flex: 1,
    },
    {
      field: "saldo_viagem",
      headerName: "Saldo $",
      flex: 1,
      valueFormatter: (params) => prettyNumber(params?.value, "cents"),
    },
  ];

  useEffect(() => {
    if ((datePicked != null) & (accessToken !== "")) {
      getRomaneioSummary(
        accessToken,
        logout,
        datePicked.format("YYYY-MM-DD")
      ).then((value) => {
        setRomaneioSummary(value);
      });
    }
  }, [accessToken, datePicked, logout]);

  useEffect(() => {
    if (accessToken !== "") {
      apiPost(
        "/cteAvailable",
        { filterDate: datePicked },
        accessToken,
        logout
      ).then((value) => {
        if (value[0].valor_disponivel_cte == null) {
          setCteAvailable({ valor_disponivel_cte: 0 });
        } else {
          setCteAvailable(value[0]);
        }
      });
    }
  }, [accessToken, datePicked, logout]);

  useEffect(() => {
    if ((datePicked != null) & (accessToken != "")) {
      getRomaneioAgregado(
        accessToken,
        logout,
        datePicked.format("YYYY-MM-DD")
      ).then((value) => {
        // value.map((e) => {
        //   e.sum = prettyNumber(e.sum, "cents");
        // });
        setAgregadoDailyContent(value);
      });
    }
  }, [accessToken, datePicked, logout]);

  useEffect(() => {
    if (agregadoSelected != null) {
      const payload = {
        cnpjCpf: agregadoSelected.map((e) => {
          return e.agregado_cnpj_cpf;
        }),
        date: datePicked.format("YYYY-MM-DD"),
      };

      getRomaneioAgregadoDetailed(accessToken, logout, payload).then(
        (value) => {
          // value.map((e) => {
          //   e.servico = prettyNumber(e.servico, "cents");
          //   e.adiantamento = prettyNumber(e.adiantamento, "cents");
          //   e.total = prettyNumber(e.total, "cents");
          // });
          setAgregadoDetailedContent(value);
        }
      );
    }
  }, [accessToken, agregadoSelected, logout, datePicked]);

  return (
    <Box m="20px">
      {/* Row com DatePICKER */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
        gridAutoRows="0"
        position
        gap="20px"
        mt="60px"
        marginBottom="40px"
      >
        <Box
          gridColumn={`span 6`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Header
              title="Verificação de dados"
              subtitle="Romaneios e CT-e submetidas"
            />
          </Box>
        </Box>

        <Box
          gridColumn={`span 6`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            sx={{ minWidth: "100px", width: "50%" }}
          >
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={dayjs(datetime)}
                minDate={dayjs(datetime).subtract(30, "days")}
                maxDate={dayjs(datetime).add(1, "days")}
                sx={{ marginBottom: "30px" }}
                label={"Data para verificar"}
                onChange={(newValue) => {
                  setDatePicker(newValue);
                  setAgregadoDetailedContent(null);
                  setAgregadoDailyContent(null);
                  setAgregado(null);
                }}
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Box>

      {/* GRID SYSTEM */}
      {/* Day Zero */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
        gridAutoRows="140px"
        position
        gap="20px"
        marginBottom="2px"
      >
        {
          <StatBox
            span="6"
            BigNumber={newRomaneioSummary.totalValue}
            BigNumberType="cents"
            title={`Total Agregado do dia ${newRomaneioSummary.date}`}
            percNumber=""
            icon={
              <PaidIcon
                sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
              />
            }
          />
        }
        {
          <StatBox
            span="6"
            BigNumber={cteAvailable.valor_disponivel_cte}
            BigNumberType="cents"
            title="Valor total em CTE disponível"
            percNumber=""
            icon={
              <ReceiptLongIcon
                sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
              />
            }
          />
        }
      </Box>

      <Box textAlign="center" mt="30px" marginBottom="30px">
        <hr
          style={{
            border: "dotted 2px",
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
          }}
        ></hr>
      </Box>

      {/* Row com DatePICKER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Verificação de Romaneios"
          subtitle="Por Agregado e por Romaneio"
        />
      </Box>

      {/* Row com tabelas */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
        gridAutoRows="0"
        position
        gap="20px"
        mt="0"
      >
        {agregadoDailyContent && (
          <Box gridColumn={`span 6`}>
            <Table
              selectionFun={setAgregado}
              checkBoxSelection={true}
              jsonData={agregadoDailyContent}
              columns={columnsAgregadoDaily}
              fileName="agregadoAcumulado"
            />
          </Box>
        )}

        {agregadoDetailedContent && agregadoDetailedContent.length !== 0 && (
          <Box gridColumn={`span 6`}>
            <Table
              checkBoxSelection={false}
              jsonData={agregadoDetailedContent}
              columns={columnsAgregadoDetailed}
              fileName="agregadoDetalhado"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RondologDataVerification;
