import { Box, useTheme, Typography, Fab } from "@mui/material";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import StorageIcon from "@mui/icons-material/Storage";
import { apiPost } from "../../global/utilFunctions/apiRequests";
import ConfirmationFab from "../buttons/ConfirmationFab";

const StatBoxCheckoutResolution = ({
	span,
	title,
	subtitle,
	refresFun,
	wtsDisabled,
	docDisabled,
	insertDbDisabled,
	dbName,
	filterDate,
	ops,
}) => {
	const theme = useTheme();
	const accessToken = useSelector((state) => state.auth0.accessToken);
	const user = useSelector((state) => state.auth0.user);
	const { logout } = useAuth0();

	return (
		<Box
			gridColumn={`span ${span}`}
			backgroundColor={theme.palette.primary.light}
			display="flex"
			alignItems="center"
			justifyContent="center"
			sx={{ boxShadow: 1, minWidth: 300 }}
		>
			<Box width="100%" m="0 30px">
				<Box display="flex" justifyContent="space-between">
					<Box>
						<Typography variant="h3" fontWeight="bold" sx={{ color: theme.palette.neutral.light }}>
							{title}
						</Typography>
						<Typography variant="h3" fontWeight="bold" sx={{ color: theme.palette.secondary.main }}>
							{subtitle}
						</Typography>
					</Box>

					<Box display="flex" sx={{ "& > :not(style)": { m: 0, mr: 0.2 } }}>
						<ConfirmationFab
							disabled={wtsDisabled}
							icon={<WhatsAppIcon />}
							confirmMsg="Mandar mensagens?"
							confirmFun={() => {
								const resp = apiPost(
									"/gestorConfirmCheckout",
									{
										dbName: dbName,
										filterDate: filterDate,
										userInfo: {
											email: user.email,
										},
										ops: ops,
									},
									accessToken,
									logout
								);

								const timeout = setTimeout(() => {
									refresFun((e) => !e);
								}, 3000);
							}}
						/>
						<ConfirmationFab
							disabled={docDisabled}
							icon={<ShoppingCartCheckoutIcon />}
							confirmMsg="Realizar compra?"
							confirmFun={() => {
								const resp = apiPost(
									"/gestorDocStart",
									{
										dbName: dbName,
										filterDate: filterDate,
										userInfo: {
											email: user.email,
										},
										ops: ops,
									},
									accessToken,
									logout
								);

								console.log(resp.data);

								const timeout = setTimeout(() => {
									refresFun((e) => !e);
								}, 3000);
							}}
						/>

						<ConfirmationFab
							disabled={insertDbDisabled}
							icon={<StorageIcon />}
							confirmMsg="Inserir a compra no DB?"
							confirmFun={() => {
								const resp = apiPost(
									"/gestorSubmitRemessaDb",
									{
										dbName: dbName,
										filterDate: filterDate,
										userInfo: {
											email: user.email,
										},
										ops: ops,
									},
									accessToken,
									logout
								);
								const timeout = setTimeout(() => {
									refresFun((e) => !e);
								}, 3000);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default StatBoxCheckoutResolution;
