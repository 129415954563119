import { Typography, useTheme } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colorTokens } from "../../theme";

export const FaqQuestion = ({ question, answer }) => {
	const theme = useTheme();
	const colors = colorTokens(theme.palette.mode);

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.neutral.dark }} />}
				sx={{
					backgroundColor: theme.palette.neutral.light,
				}}
			>
				<Typography color={theme.palette.neutral.dark} variant="h5" fontWeight="bold">
					{question}
				</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					backgroundColor: colors.grey[100],
					borderBlockColor: theme.palette.background,
				}}
			>
				<Typography color={theme.palette.neutral.dark} variant="h6">
					{answer}
				</Typography>
			</AccordionDetails>
		</Accordion>
	);
};
