import Header from "../../global/layoutComponents/Header";
import { CheckoutStatBox } from "../../global/boxComponents/CheckoutStatBox";
import { Box, useTheme, Typography, Fab } from "@mui/material";
import { SelectForm } from "../../global/inputComponents/SelectForm";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutIf401 } from "../../global/utilFunctions/logoutIf400";
import axios from "axios";

import { ConfirmDialog } from "../../global/inputComponents/ConfirmDialog";
import { prettyNumber } from "../../global/utilFunctions/mathUtils";

import PaidIcon from "@mui/icons-material/Paid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleIcon from "@mui/icons-material/People";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import StorageIcon from "@mui/icons-material/Storage";
import CheckoutTesteViewer from "../../global/checkoutComponent/CheckoutTesteViewer.jsx";
import CheckoutXamaViewer from "../../global/checkoutComponent/CheckoutXamaViewer.jsx";
import CheckoutXptoViewer from "../../global/checkoutComponent/CheckoutXptoViewer.jsx";
import CheckoutRondologViewer from "../../global/checkoutComponent/CheckoutRondologViewer.jsx";

import { apiPost, apiGet } from "../../global/utilFunctions/apiRequests";

const GestorCheckoutResolution = () => {
	const theme = useTheme();
	const { logout } = useAuth0();
	const accessToken = useSelector((state) => state.auth0.accessToken);
	const user = useSelector((state) => state.auth0.user);

	const [fundName, setFundName] = useState("");
	const [fundOptions, setFundOptions] = useState([]);

	useEffect(() => {
		if (accessToken != "") {
			apiGet("/availableFunds", accessToken, logout)
				.then((value) => {
					value.map((e) => {
						delete Object.assign(e, { key: e.dbname })["dbname"];
						delete Object.assign(e, { value: e.fundname })["fundname"];
					});
					setFundOptions(value);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [accessToken, logout]);

	switch (fundName) {
		case "xpto_prod":
		case "xpto_dev":
			var content = (
				<CheckoutXptoViewer
					fundName={fundName}
					setFundName={setFundName}
					fundOptions={fundOptions}
					theme={theme}
					logout={logout}
					accessToken={accessToken}
				/>
			);
			break;
		case "xama_prod":
		case "xama_dev":
			var content = (
				<CheckoutXamaViewer
					fundName={fundName}
					setFundName={setFundName}
					fundOptions={fundOptions}
					theme={theme}
					logout={logout}
					accessToken={accessToken}
				/>
			);
			break;
		case "rondolog_prod":
		case "rondolog_dev":
			var content = (
				<CheckoutRondologViewer
					fundName={fundName}
					setFundName={setFundName}
					fundOptions={fundOptions}
					theme={theme}
					logout={logout}
					accessToken={accessToken}
				/>
			);
			break;
		case "teste":
			var content = (
				<CheckoutTesteViewer
					fundName={fundName}
					setFundName={setFundName}
					fundOptions={fundOptions}
					theme={theme}
					logout={logout}
					accessToken={accessToken}
				/>
			);

			break;
		default:
			var content = (
				<Box m="20px">
					<Header title="Selecione um Fundo" subtitle="" />
				</Box>
			);
	}

	return (
		<>
			<Box m="20px">
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					gridAutoRows="0"
					position
					gap="20px"
					mt="60px"
					marginBottom="40px"
				>
					<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
						<Box>
							<Header title="CHECKOUT RESOLUTION" subtitle="Resolver pendências dos checkouts" />
						</Box>
					</Box>

					<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
						<SelectForm label="Fundos" varValue={fundName} setFun={setFundName} optionArray={fundOptions} />
					</Box>
				</Box>
				<>{content}</>
			</Box>
		</>
	);
};

export default GestorCheckoutResolution;
