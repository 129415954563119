import { Box, useTheme, Button, Typography, Paper } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import Dialog, { DialogProps } from "@mui/material/Dialog";
import { useState, useEffect } from "react";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { colorTokens } from "../../theme";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPost, apiGet } from "../../global/utilFunctions/apiRequests";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import Carousel from "react-material-ui-carousel";

const makeDocTable = (obj) => {
  const objKeys = Object.entries(obj);
  const filtered = objKeys.filter(
    ([k, v]) =>
      k != "id" &&
      k != "signedUrlCNH" &&
      k != "signedUrlSelfie" &&
      k != "cnhPic" &&
      k != "selfie"
  );
  const newObj = Object.fromEntries(filtered);
  const result = Object.keys(newObj).map((key, index) => (
    <tr key={index}>
      <td>{key}:</td>
      <td>{newObj[key]}</td>
    </tr>
  ));

  return result;
};

const TableForApproval = (props) => {
  const theme = useTheme();
  if (props.cedente === true)
    props.columns.push({
      field: "action",
      headerName: "Action",
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: renderFunctionBuilder(props.dbName, props.triggerRefresh),
    });

  return (
    <Box
      m="5px 0 0 0 "
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: theme.palette.secondary.main,
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme.palette.primary.dark,
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: theme.palette.primary.light,
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: theme.palette.primary.dark,
        },
        "& .MuiCheckbox-root": {
          color: `${theme.palette.secondary.main} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${theme.palette.neutral.light} !important`,
        },
      }}
    >
      <DataGrid
        autoHeight
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={props.jsonData}
        columns={props.columns}
        columnVisibilityModel={{
          // Hide columns status and traderName, the other columns will remain visible
          id: false,
        }}
        componentsProps={{
          basePopper: {
            sx: {
              "& .MuiDataGrid-menu": {
                background: `${theme.palette.primary.light} !important`,
              },

              "& .MuiPaper-root": {
                background: `${theme.palette.primary.light} !important`,
              },

              "& .MuiDataGrid-menu .MuiPaper-root": {
                background: `${theme.palette.primary.light} !important`,
              },

              "& .MuiButtonBase-root": {
                color: `${theme.palette.neutral.light} !important`,
              },

              "& .MuiSwitch-switchBase": {
                color: `${theme.palette.neutral.light} !important`,
                background: `transparent !important`,
              },
            },
          },
        }}
      />
    </Box>
  );
};

const renderFunctionBuilder = (dbName, triggerRefresh) => {
  const RenderDetailCedente = (params) => {
    const { logout } = useAuth0();
    const accessToken = useSelector((state) => state.auth0.accessToken);

    const theme = useTheme();
    const colors = colorTokens(theme.palette.mode);
    const azulPlanalto = colors.azulPlanalto[200];

    const user = useSelector((state) => state.auth0.user);

    const approvalFunction = (dbName, user, row, action) => {
      apiPost(
        "/GestorCadastroDocApproval",
        {
          dbName: dbName,
          userInfo: user,
          ...row,
          action: action,
        },
        accessToken,
        logout
      )
        .then((value) => {
          handleClose();
          triggerRefresh();
          console.log(value);
        })
        .catch((err) => {
          handleClose();
          triggerRefresh();
          console.log(err);
        });
    };

    const [open, setOpen] = useState(false);
    const [reproveDisable, setReproveDisable] = useState(false);
    const [approveDisable, setApproveDisable] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const imageItemarr = [
      { id: 1, image: params.row.signedUrlCNH, title: "Documento" },
      { id: 2, image: params.row.signedUrlSelfie, title: "selfie" },
    ];

    const ImageItem = (props) => {
      return (
        <Paper>
          <img
            src={props.item.image}
            alt={props.item.title}
            width="100%"
            height="50%"
            class="center"
          />

          <Typography id="carousel" variant="h6" component="h3">
            {props.item.title}
          </Typography>
        </Paper>
      );
    };
    const BoxWrapper = ({ children }) => {
      return (
        <Box
          display="flex"
          gridAutoRows="0"
          position
          gap="20px"
          marginTop="10px"
          marginBottom="20px"
          justifyContent="center"
          alignItems="center"
          sx={{
            mx: "auto",
            maxHeight: "40%",
            maxWidth: "50%",
          }}
        >
          {children}
        </Box>
      );
    };

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpen}
        >
          Imagem
        </Button>
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          open={open}
          onClose={handleClose}
          fullScreen
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AppBar
            sx={{ position: "relative" }}
            style={{ background: theme.palette.primary.dark }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="default"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Box marginTop={"60px"}>
            <BoxWrapper>
              <Typography id="modal-modal-title" variant="h6" component="h3">
                <table>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>conteudo</th>
                    </tr>
                  </thead>
                  <tbody>{makeDocTable(params.row)}</tbody>
                </table>
              </Typography>
            </BoxWrapper>

            <Box
              display="relative"
              gap="20px"
              justifyContent="center"
              alignItems="center"
              marginBottom={"70px"}
              marginTop={"70px"}
              sx={{
                mx: "auto",
                maxHeight: "40%",
                maxWidth: "50%",
              }}
            >
              <Carousel>
                {imageItemarr.map((item, i) => (
                  <ImageItem key={i} item={item} width={900} height={500} />
                ))}
              </Carousel>

              <BoxWrapper>
                <Button
                  disabled={reproveDisable}
                  variant="contained"
                  onClick={() => {
                    setReproveDisable(true);
                    setApproveDisable(true);
                    approvalFunction(dbName, user, params.row, "reprove");
                  }}
                  sx={{
                    color: theme.palette.neutral.light,
                    backgroundColor: theme.palette.danger.main,
                  }}
                >
                  Reprovado!
                </Button>
                <Button
                  disabled={approveDisable}
                  variant="contained"
                  onClick={() => {
                    setReproveDisable(true);
                    setApproveDisable(true);
                    approvalFunction(dbName, user, params.row, "approve");
                  }}
                  sx={{
                    color: theme.palette.neutral.light,
                    backgroundColor: theme.palette.primary.dark,
                  }}
                >
                  Aprovado
                </Button>
              </BoxWrapper>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  };

  return RenderDetailCedente;
};

export default TableForApproval;
