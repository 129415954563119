import Header from "../../global/layoutComponents/Header";
import { Box, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import StatBox from "../../global/boxComponents/StatBox";

import PaidIcon from "@mui/icons-material/Paid";

import { prettyNumber } from "../../global/utilFunctions/mathUtils";
import { apiGet, apiPost } from "../../global/utilFunctions/apiRequests";
import Table from "../../global/tableComponents/Table";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import TextField from "@mui/material/TextField";
import MopedIcon from "@mui/icons-material/Moped";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import PermDeviceInformationIcon from "@mui/icons-material/PermDeviceInformation";

const XamaPersonStatus = () => {
	const theme = useTheme();
	const datetime = new Date().toISOString().slice(0, 10);
	const { logout } = useAuth0();
	const accessToken = useSelector((state) => state.auth0.accessToken);

	const [romaneioStatusPerson, setRomaneioStatusPerson] = useState(null);
	const [documentNumberPerson, setDocumentNumberPerson] = useState(null);

	const columnsPedidos = [
		{ field: "id", headerName: "ID", flex: 1, hide: true },
		{ field: "cnpj_cpf_entregador", headerName: "Documento", flex: 1 },
		{
			field: "taxa_total_entregador",
			headerName: "Taxa Total Entregador",
			flex: 1,
			valueFormatter: (params) => prettyNumber(params?.value, "cents"),
		},
		{
			field: "data_romaneio",
			headerName: "Data Romaneio",
			flex: 1,
			valueFormatter: (params) => params?.value.slice(0, 10),
		},
	];

	const columnsGarantido = [
		{ field: "id", headerName: "ID", flex: 1, hide: true },
		{ field: "cnpj_cpf_entregador", headerName: "Documento", flex: 1 },
		{ field: "telefone", headerName: "Telefone", flex: 1 },
		{
			field: "valor_garantido",
			headerName: "Valor Garantido",
			flex: 1,
			valueFormatter: (params) => prettyNumber(params?.value, "cents"),
		},
		{
			field: "data_romaneio",
			headerName: "Data Romaneio",
			flex: 1,
			valueFormatter: (params) => params?.value.slice(0, 10),
		},
	];

	const columnsCadastro = [
		{ field: "id", headerName: "ID", flex: 1, hide: true },
		{ field: "telefone", headerName: "Telefone", flex: 1 },
		{ field: "is_new", headerName: "Cadastro Em Aguardo", flex: 1 },
	];

	useEffect(() => {
		if (accessToken != "" && documentNumberPerson != null) {
			apiPost(
				"/romaneioStatusPerson",
				{ numeroDocumento: documentNumberPerson, dbName: "xama" },
				accessToken,
				logout
			).then((value) => {
				setRomaneioStatusPerson(value);
			});
		}
	}, [accessToken, documentNumberPerson]);
	console.log(documentNumberPerson);
	console.log(romaneioStatusPerson);
	return (
		<Box m="20px">
			{/* Row com DatePICKER */}
			<Box
				display="grid"
				gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
				gridAutoRows="0"
				position
				gap="20px"
				mt="60px"
				marginBottom="40px"
			>
				<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<Header title="Checagem" subtitle="Checagem de dados e Cadastro" />
					</Box>
				</Box>

				<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
					<TextField
						id="ndocument"
						label="CPF/CNPJ"
						variant="outlined"
						placeholder="Número documento"
						inputProps={{
							onKeyPress: (event) => {
								if (event.key === "Enter") {
									// write your functionality here
									setDocumentNumberPerson(event.target.value);
									event.preventDefault();
								}
							},
						}}
					/>
				</Box>
			</Box>
			{/* GRID SYSTEM */}
			{/* Row com TED LIQUIDADA ou Concluida */}
			{romaneioStatusPerson && romaneioStatusPerson.msg == "Informe um número de documento" && (
				<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
					<Header title="Informe um número de documento Válido" subtitle="" />
				</Box>
			)}
			{romaneioStatusPerson && romaneioStatusPerson.length != 0 && (
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					gridAutoRows="140px"
					position
					gap="20px"
					marginBottom="2px"
				>
					{
						<StatBox
							span="4"
							BigNumber={romaneioStatusPerson.summary.acumuladoPedidos}
							BigNumberType="cents"
							title={`Valor Face Pedidos`}
							percNumber=""
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
					{
						<StatBox
							span="4"
							BigNumber={romaneioStatusPerson.summary.acumuladoGarantido}
							BigNumberType="cents"
							title="Valor Face GARANTIDO"
							percNumber=""
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<StatBox
							span="4"
							BigNumber={romaneioStatusPerson.summary.semCadastro === true ? "SEM Cadastro" : "Com Cadastro"}
							BigNumberType="asIs"
							title="Status Cadastro"
							percNumber=""
							icon={<PermDeviceInformationIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
				</Box>
			)}

			{romaneioStatusPerson && romaneioStatusPerson.length != 0 && (
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					gridAutoRows="140px"
					position
					gap="20px"
					marginBottom="2px"
				>
					{
						<StatBox
							span="4"
							BigNumber={
								romaneioStatusPerson.garantido.length !== 0 ? "Possui dados no Garantido" : "Não possui Garantido"
							}
							BigNumberType="asIs"
							title="Status GARANTIDO"
							percNumber=""
							icon={<WorkspacePremiumIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<StatBox
							span="4"
							BigNumber={romaneioStatusPerson.pedidos.length !== 0 ? "Possui Corridas" : "Não possui corridas"}
							BigNumberType="asIs"
							title="Status PEDIDOS"
							percNumber=""
							icon={<MopedIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<StatBox
							span="4"
							BigNumber={
								romaneioStatusPerson.summary.telefoneIgual == true
									? "Telefone Garantido Igual Cadastro"
									: romaneioStatusPerson.summary.telefoneIgual == null
									? "Não teve garantido"
									: "Telefone Cadastro DIFERENTE do Garantido"
							}
							BigNumberType="asIs"
							title="StatusTelefone"
							percNumber=""
							icon={<LocalPhoneIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
				</Box>
			)}

			<Box
				display="grid"
				gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
				gridAutoRows="0"
				position
				gap="20px"
				marginBottom="200px"
			>
				{romaneioStatusPerson && romaneioStatusPerson.length != 0 && romaneioStatusPerson.cadastro.length !== 0 && (
					<Box gridColumn={`span 6`}>
						<Table
							checkBoxSelection={false}
							jsonData={romaneioStatusPerson.cadastro}
							columns={columnsCadastro}
							fileName="Cadastro"
						/>
					</Box>
				)}

				{romaneioStatusPerson && romaneioStatusPerson.length != 0 && romaneioStatusPerson.garantido.length !== 0 && (
					<Box gridColumn={`span 6`}>
						<Table
							checkBoxSelection={false}
							jsonData={romaneioStatusPerson.garantido}
							columns={columnsGarantido}
							fileName="Garantido"
						/>
					</Box>
				)}
			</Box>

			{/* LINHA */}
			<Box textAlign="center" mt="30px" marginBottom="30px">
				<hr
					style={{
						border: "dotted 2px",
						color: theme.palette.secondary.main,
						backgroundColor: theme.palette.primary.main,
					}}
				></hr>
			</Box>

			{romaneioStatusPerson && romaneioStatusPerson.length != 0 && romaneioStatusPerson.pedidos.length !== 0 && (
				<Box mt="30px">
					<Table
						checkBoxSelection={false}
						jsonData={romaneioStatusPerson.pedidos}
						columns={columnsPedidos}
						fileName="Pedidos"
					/>
				</Box>
			)}
		</Box>
	);
};

export default XamaPersonStatus;
