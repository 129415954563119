import Header from "../../global/layoutComponents/Header";
import { Box, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import StatBox from "../../global/boxComponents/StatBox";

import PaidIcon from "@mui/icons-material/Paid";

import { prettyNumber } from "../../global/utilFunctions/mathUtils";
import { apiGet, apiPost } from "../../global/utilFunctions/apiRequests";
import Table from "../../global/tableComponents/Table";

const XamaAntecipacaoViz = () => {
	const theme = useTheme();
	const datetime = new Date().toISOString().slice(0, 10);
	const { logout } = useAuth0();
	const accessToken = useSelector((state) => state.auth0.accessToken);

	const [romaneioAntecipacaoViz, setRomaneioAntecipacaoViz] = useState(null);
	const [datePicked, setDatePicker] = useState(dayjs(datetime));

	useEffect(() => {
		if ((datePicked != null) & (accessToken != "")) {
			apiPost(
				"/romaneioAntecipacaoViz",
				{ filterDate: datePicked.format("YYYY-MM-DD"), dbName: "xama" },
				accessToken,
				logout
			).then((value) => {
				setRomaneioAntecipacaoViz(value);
			});
		}
	}, [accessToken, datePicked]);

	const columnsOfAntecipacoesTable = [
		{ field: "id", headerName: "ID", flex: 1, hide: true },
		{ field: "cnpjCpf", headerName: "Documento", flex: 1 },
		{
			field: "valorFace",
			headerName: "Valor Face",
			flex: 1,
			valueFormatter: (params) => prettyNumber(params?.value, "cents"),
		},
		{
			field: "valorCompra",
			headerName: "Valor Compra",
			flex: 1,
			valueFormatter: (params) => prettyNumber(params?.value, "cents"),
		},
		{
			field: "dataRomaneio",
			headerName: "Data Romaneio",
			flex: 1,
			valueFormatter: (params) => params?.value.slice(0, 10),
		},
		{
			field: "dataVencimento",
			headerName: "Data Vencimento",
			flex: 1,
			valueFormatter: (params) => params?.value.slice(0, 10),
		},
	];

	console.log(romaneioAntecipacaoViz);
	return (
		<Box m="20px">
			{/* Row com DatePICKER */}
			<Box
				display="grid"
				gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
				gridAutoRows="0"
				position
				gap="20px"
				mt="60px"
				marginBottom="40px"
			>
				<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<Header title="Solicitações" subtitle="Antecipações solicitadas" />
					</Box>
				</Box>

				<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
					<LocalizationProvider dateAdapter={AdapterDayjs} sx={{ minWidth: "100px", width: "50%" }}>
						<DemoContainer components={["DatePicker"]}>
							<DatePicker
								format="YYYY-MM-DD"
								defaultValue={dayjs(datetime)}
								minDate={dayjs(datetime).subtract(40, "days")}
								maxDate={dayjs(datetime).add(1, "days")}
								sx={{ marginBottom: "30px" }}
								label={"Data para verificar"}
								onChange={(newValue) => {
									setRomaneioAntecipacaoViz(null);
									setDatePicker(newValue);
								}}
								slotProps={{
									textField: {
										readOnly: true,
									},
								}}
							/>
						</DemoContainer>
					</LocalizationProvider>
				</Box>
			</Box>

			{/* GRID SYSTEM */}
			{/* Row com TED LIQUIDADA ou Concluida */}

			{romaneioAntecipacaoViz && romaneioAntecipacaoViz.length != 0 && (
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					gridAutoRows="140px"
					position
					gap="20px"
					marginBottom="2px"
				>
					{
						<StatBox
							span="6"
							BigNumber={romaneioAntecipacaoViz.summary.valorFace}
							BigNumberType="cents"
							title={`Valor Face acumulado`}
							percNumber=""
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
					{
						<StatBox
							span="6"
							BigNumber={romaneioAntecipacaoViz.summary.valorCompra}
							BigNumberType="cents"
							title="Valor Compra acumulado"
							percNumber=""
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
				</Box>
			)}

			{romaneioAntecipacaoViz &&
				romaneioAntecipacaoViz.length != 0 &&
				romaneioAntecipacaoViz.antecipacoes.length !== 0 && (
					<Box gridColumn={`span 6`}>
						<Table
							checkBoxSelection={false}
							jsonData={romaneioAntecipacaoViz.antecipacoes}
							columns={columnsOfAntecipacoesTable}
							fileName="SolicitacoesAntecipacoes"
						/>
					</Box>
				)}
		</Box>
	);
};

export default XamaAntecipacaoViz;
