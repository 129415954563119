import { Box, useTheme, Typography, Fab } from "@mui/material";
import { useState, useEffect } from "react";
import { ConfirmDialog } from "../../global/inputComponents/ConfirmDialog";

const ConfirmationFab = (props) => {
  const theme = useTheme();
  const { disabled, icon, confirmMsg, confirmFun } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);

  return (
    <Box display="flex" sx={{ m: 1, position: "relative" }}>
      <Fab
        color="primary"
        aria-label="add"
        disabled={disabled}
        onClick={() => setConfirmOpen(true)}
      >
        {icon}
      </Fab>
      <ConfirmDialog
        title=""
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmFun}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{
            color: theme.palette.neutral.light,
            marginBottom: "10px",
          }}
        >
          Confirmação
        </Typography>

        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{ color: theme.palette.neutral.light }}
        >
          {confirmMsg}
        </Typography>
      </ConfirmDialog>
    </Box>
  );
};

export default ConfirmationFab;
