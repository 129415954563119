import { useState, useContext } from "react";
import { Sidebar, Menu, MenuItem, SubMenu, sidebarClasses, menuClasses } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { colorTokens } from "../../theme";
import { sidebarContext } from "./SidebarContext";
import { Link, NavLink } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DomainVerificationOutlinedIcon from "@mui/icons-material/DomainVerificationOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";

const SubMenuItem = (props, icon) => {
	const theme = useTheme();
	const colors = colorTokens(theme.palette.mode);
	console.log("MEnuClasses");
	console.log(menuClasses);
	return (
		<SubMenu
			label={props.label}
			icon={props.icon}
			rootStyles={{
				["& > ." + menuClasses.button]: {
					background: theme.palette.primary.dark,
					backgroundColor: theme.palette.primary.dark,
					"&:hover": {
						background: colors.azulPlanalto[500],
						backgroundColor: theme.palette.primary.light,
					},
				},
				["." + menuClasses.subMenuContent]: {
					background: theme.palette.primary.light,
					backgroundColor: theme.palette.primary.dark,
					"&:hover": {
						backgroundColor: theme.palette.primary.dark,
					},
				},
			}}
		>
			{props.children}
		</SubMenu>
	);
};
const Item = ({ title, to, icon, selected, setSelected }) => {
	const theme = useTheme();

	const colors = colorTokens(theme.palette.mode);
	const { isToggled, setToggled } = useContext(sidebarContext);

	return (
		<MenuItem
			component={<Link to={to} className="link" />}
			active={selected === title}
			onClick={() => {
				setSelected(title);
				setToggled(false);
			}}
			icon={icon}
		>
			<Typography>{title}</Typography>
		</MenuItem>
	);
};

const Sidebar2 = (props) => {
	const theme = useTheme();
	const colors = colorTokens(theme.palette.mode);
	const { isToggled, setToggled } = useContext(sidebarContext);
	const [selected, setSelected] = useState("Home");
	const screens = process.env.REACT_APP_SCREENS.split(" ");

	console.log("SIDEBARCLASSEs");
	console.log(sidebarClasses);
	return (
		<div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
			<Sidebar
				width="300px"
				rootStyles={{
					[`.${sidebarClasses.container}`]: {
						background: theme.palette.primary.dark,
						padding: "5px 35px 5px 20px !important",
					},

					[`.${sidebarClasses.backdrop}`]: {
						background: colors.azulPlanalto[500],
						backgroundColor: "transparent",
					},
					[`border-color`]: theme.palette.primary.dark,
					[`&.${sidebarClasses.broken}`]: {
						zIndex: "2000 !important",
					},

					[`.${sidebarClasses.broken}`]: {
						background: colors.azulPlanalto[500],
						backgroundColor: "transparent",
						padding: "5px 35px 5px 20px !important",
					},
				}}
				rtl={false}
				// collapsed={isCollapsed}
				onBackdropClick={() => setToggled(false)}
				toggled={isToggled}
				breakPoint="all"
			>
				<Menu
					menuItemStyles={{
						button: ({ isSubmenu, level, active, disabled }) => {
							// only apply styles on first level elements of the tree
							if (level >= 0)
								return {
									color: disabled ? theme.palette.neutral.light : theme.palette.neutral.light,
									backgroundColor: active ? undefined : undefined,
									"&:hover": {
										background: theme.palette.primary.light,
										backgroundColor: theme.palette.primary.light,
									},
								};
						},
					}}
				>
					{isToggled && (
						<MenuItem
							disabled={false}
							style={{
								margin: "10px 0px 10px 0px",
								color: colors.grey[100],
								backgroundColor: "transparent",
							}}
						>
							<Box mb="30px" mt="30px">
								<Box textAlign="center" mt="">
									<Typography
										variant="h2"
										color={theme.palette.neutral.light}
										fontWeight="bold"
										sx={{ m: "10px 10 0 0" }}
									>
										{props.name}
									</Typography>
									<Typography variant="h5" color={theme.palette.secondary.main}>
										Planalto Capital
									</Typography>
								</Box>
							</Box>
						</MenuItem>
					)}

					<Box textAlign="center" mt="20px">
						<hr color={theme.palette.secondary.main}></hr>
					</Box>

					<Item title="Home" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />
					{screens.includes("rondologRomaneio") && (
						<Item
							title="Upload Romaneio"
							to="/romaneio"
							icon={<LocalShippingOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("rondologAgregado") && (
						<Item
							title="Upload Agregado"
							to="/cadastroagregado"
							icon={<PersonOutlineIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("cteRondolog") && (
						<Item
							title="Upload CT-e"
							to="/cte"
							icon={<ReceiptLongIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{(screens.includes("ifoodFinanceiroUpload") ||
						screens.includes("ifoodPerformanceUpload") ||
						screens.includes("ifoodCadastroUpload")) && (
						<SubMenuItem label="Ifood" icon={<TwoWheelerIcon />}>
							{screens.includes("ifoodFinanceiroUpload") && (
								<Item
									title="Ifood Financeiro"
									to="/ifoodFinanceiro"
									icon={<LocalShippingOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("ifoodPerformanceUpload") && (
								<Item
									title="Ifood Performance"
									to="/ifoodPerformance"
									icon={<LocalShippingOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("ifoodCadastroUpload") && (
								<Item
									title="Ifood Cadastro"
									to="/ifoodCadastro"
									icon={<ReceiptLongIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{(screens.includes("muveFinanceiro") ||
						screens.includes("muveFinanceiroGarantido") ||
						screens.includes("muvePedidos")) && (
						<SubMenuItem label="Muve" icon={<DeliveryDiningIcon />}>
							{screens.includes("muvePedidos") && (
								<Item
									title="Muve Pedidos"
									to="/muvePedidos"
									icon={<LocalShippingOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("muveFinanceiro") && (
								<Item
									title="Muve Financeiro"
									to="/muveFinanceiro"
									icon={<ReceiptLongIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("muveFinanceiroGarantido") && (
								<Item
									title="Muve Garantido"
									to="/muveFinanceiroGarantido"
									icon={<ReceiptLongIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{(screens.includes("xptoFinanceiro") ||
						screens.includes("xptoFinanceiroGarantido") ||
						screens.includes("xptoPedidos")) && (
						<SubMenuItem label="Xpto" icon={<DeliveryDiningIcon />}>
							{screens.includes("xptoPedidos") && (
								<Item
									title="xpto Pedidos"
									to="/xptoPedidos"
									icon={<LocalShippingOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("xptoFinanceiro") && (
								<Item
									title="xpto Financeiro"
									to="/xptoFinanceiro"
									icon={<ReceiptLongIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("xptoFinanceiroGarantido") && (
								<Item
									title="xpto Garantido"
									to="/xptoFinanceiroGarantido"
									icon={<ReceiptLongIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{screens.includes("rondologDataVerification") && (
						<Item
							title="Verificação de dados"
							to="/verificacaodados"
							icon={<DomainVerificationOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("checkoutRomaneioCte") && (
						<Item
							title="Checkout"
							to="/checkout"
							icon={<ShoppingCartCheckoutOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("gestorCheckout") && (
						<Item
							title="Resolução de Checkout"
							to="/checkoutResolution"
							icon={<ShoppingCartOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("gestorBaixa") && (
						<Item
							title="Baixa de Creditos"
							to="/gestorBaixa"
							icon={<AccountBalanceIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("gestorDocApproval") && (
						<Item
							title="Aprovação de Documentos"
							to="/gestorDocApproval"
							icon={<ContactPageIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("gestorLiquidacao") && (
						<Item
							title="Liquidação Créditos"
							to="/gestorLiquidacao"
							icon={<AttachMoneyIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("rondologPaymentVerify") && (
						<Item
							title="Status Pagamento"
							to="/rondologPayments"
							icon={<AccountBalanceIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xamaCheckout") && (
						<Item
							title="Checkout"
							to="/xamacheckout"
							icon={<DomainVerificationOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xamaPaymentVerify") && (
						<Item
							title="Status Pagamento"
							to="/xamaPayments"
							icon={<AccountBalanceIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xamaAntecipacaoViz") && (
						<Item
							title="Solicitações"
							to="/antecipacaoViz"
							icon={<ContactPageIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xamaGraphStatistics") && (
						<Item
							title="Estatísticas"
							to="/xamaGraphStatistics"
							icon={<QueryStatsIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xamaListaEntregadores") && (
						<Item
							title="Lista Entregadores"
							to="/listaEntregadores"
							icon={<SportsMotorsportsIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xamaPersonStatus") && (
						<Item
							title="Checagem Pessoas"
							to="/personStatus"
							icon={<PersonOutlineIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xptoCheckout") && (
						<Item
							title="Checkout"
							to="/xptocheckout"
							icon={<DomainVerificationOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xptoPaymentVerify") && (
						<Item
							title="Status Pagamento"
							to="/xptoPayments"
							icon={<AccountBalanceIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xptoAntecipacaoViz") && (
						<Item
							title="Solicitações"
							to="/antecipacaoViz"
							icon={<ContactPageIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xptoPersonStatus") && (
						<Item
							title="Checagem Pessoas"
							to="/personStatus"
							icon={<PersonOutlineIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}
					{/* <Item
            title="Faq"
            to="/faq"
            icon={<HelpOutlineOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          /> */}
				</Menu>
			</Sidebar>
		</div>
	);
};

export default Sidebar2;
