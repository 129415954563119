import Header from "../layoutComponents/Header";
import { Box, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import TableForApproval from "../tableComponents/TableForApproval";

import { apiPost, apiGet } from "../../global/utilFunctions/apiRequests";

export default function DocAprovalRondologViewer(props) {
  const theme = useTheme();

  const [refreshDocList, triggerRefresh] = useState(false);
  const [docListForApproval, setDocListForApproval] = useState([]);
  const [firstSearch, setFirstSearch] = useState(false);

  const [makingSearch, setMakingSearch] = useState(false);

  useEffect(() => {
    if ((props.fundName !== "") & (props.accessToken !== "")) {
      setMakingSearch(true);
      apiPost(
        "/GestorCadastroDocList",
        {
          dbName: props.fundName,
        },
        props.accessToken,
        props.logout
      )
        .then((value) => {
          setDocListForApproval(value);
          setFirstSearch(true);
          setMakingSearch(false);
        })
        .catch((err) => {
          setDocListForApproval(undefined);
          console.log(err);
          setMakingSearch(false);
        });
    }
  }, [props.accessToken, props.fundName, props.logout, refreshDocList]);

  const columnsOfDocTable = [
    { field: "id", headerName: "ID", flex: 1, hide: true },
    { field: "personType", headerName: "TipoPessoa", flex: 1 },
    { field: "fullName", headerName: "Nome pessoa Física", flex: 1 },
    { field: "companyName", headerName: "Nome Empresa", flex: 1 },
    { field: "phone", headerName: "Celular/Wts", flex: 1 },
  ];

  console.log(docListForApproval.length);
  console.log(props.fundName);

  return (
    <>
      {docListForApproval !== undefined && docListForApproval.length !== 0 && (
        <Box mt="80px" marginBottom="30px">
          <TableForApproval
            jsonData={docListForApproval}
            columns={columnsOfDocTable}
            dbName={props.fundName}
            triggerRefresh={triggerRefresh}
            cedente
          />
        </Box>
      )}

      {docListForApproval === undefined &&
        props.fundName !== "" &&
        firstSearch === true && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="30px"
          >
            <Header
              title="Houve algum problema na hora de recuperar cadastros"
              subtitle=""
            />
          </Box>
        )}

      {makingSearch === true && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="30px"
        >
          <Header title="Realizando Busca" subtitle="" />
        </Box>
      )}

      {docListForApproval !== undefined &&
        docListForApproval.length === 0 &&
        firstSearch === true && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="30px"
          >
            <Header title="Não foram encontrados novos cadastros" subtitle="" />
          </Box>
        )}
      <Box>
        <hr
          style={{
            border: "dotted 2px",
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
          }}
        ></hr>
      </Box>
    </>
  );
}
