import Header from "../../global/layoutComponents/Header";
import {
  Box,
  useTheme,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutIf401 } from "../../global/utilFunctions/logoutIf400";
import { prettyNumber } from "../../global/utilFunctions/mathUtils";
import { CheckoutStatBox } from "../../global/boxComponents/CheckoutStatBox";
import StatBox from "../../global/boxComponents/StatBox";
import axios from "axios";
import PaidIcon from "@mui/icons-material/Paid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PeopleIcon from "@mui/icons-material/People";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { apiPost } from "../../global/utilFunctions/apiRequests";
import StatBoxCheckoutXama from "../../global/boxComponents/StatBoxCheckoutXama";

const BoxesRowIfood = (props) => {
  const { array, refresFun } = props;
  const theme = useTheme();

  return array.map((e) => {
    return (
      <Box key={`${e.checkout_date}${e.ops}${e.total_valor_face}`}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
          gridAutoRows="140px"
          position
          gap="60px"
          marginBottom="2px"
        >
          {
            <CheckoutStatBox
              span="2"
              BigNumber={e.ops}
              BigNumberType="asIs"
              title={`Operação`}
              icon={
                <LocalShippingIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }

          {
            <CheckoutStatBox
              span="2"
              BigNumber={e.entregadores_unicos}
              BigNumberType="integer"
              title={`Entregadores únicos`}
              icon={
                <PeopleIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }

          {
            <CheckoutStatBox
              span="2"
              BigNumber={e.ticket_medio}
              BigNumberType="cents"
              title={`Ticket Médio`}
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }

          {
            <CheckoutStatBox
              span="2"
              BigNumber={e.total_valor_face}
              BigNumberType="cents"
              title={`Valor de face acumulado`}
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }

          {
            <StatBoxCheckoutXama
              span="2"
              subtitle={"CHECKOUT"}
              refresFun={refresFun}
              confirmButtonDisabled={false}
              title={`${e.checkout_date.slice(0, 10)}`}
              ops={e.ops}
              checkoutDate={`${e.checkout_date.slice(0, 10)}`}
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }
        </Box>
      </Box>
    );
  });
};
const BoxesRowMuve = (props) => {
  const { array, refresFun } = props;
  const theme = useTheme();

  return array.map((e) => {
    return (
      <Box key={`${e.checkout_date}${e.ops}${e.total_valor_face}`}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
          gridAutoRows="140px"
          position
          gap="60px"
          marginBottom="2px"
        >
          {
            <CheckoutStatBox
              span="2"
              BigNumber={e.ops}
              BigNumberType="asIs"
              title={`Operação`}
              icon={
                <LocalShippingIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }

          {
            <CheckoutStatBox
              span="2"
              BigNumber={e.entregadores_unicos}
              BigNumberType="integer"
              title={`Entregadores únicos`}
              icon={
                <PeopleIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }

          {
            <CheckoutStatBox
              span="2"
              BigNumber={e.valor_total_garantido}
              BigNumberType="cents"
              title={`Total Valor Garantido`}
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }

          {
            <CheckoutStatBox
              span="2"
              BigNumber={e.total_valor_face}
              BigNumberType="cents"
              title={`Valor de face acumulado`}
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }

          {
            <StatBoxCheckoutXama
              span="2"
              subtitle={"CHECKOUT"}
              refresFun={refresFun}
              confirmButtonDisabled={false}
              title={`${e.checkout_date.slice(0, 10)}`}
              ops={e.ops}
              checkoutDate={`${e.checkout_date.slice(0, 10)}`}
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }
        </Box>
      </Box>
    );
  });
};

const XamaCheckout = () => {
  const theme = useTheme();

  const { logout } = useAuth0();
  const accessToken = useSelector((state) => state.auth0.accessToken);

  const [firstSearch, setFirstSearch] = useState(false);
  const [checkoutListIfood, setCheckoutListIfood] = useState([]);
  const [checkoutListMuve, setCheckoutListMuve] = useState([]);
  const [refreshList, setRefreshList] = useState(true);

  useEffect(() => {
    if (accessToken !== "") {
      apiPost(
        "/romaneioCheckoutSummary",
        {
          dbName: "xama_dev",
          ops: "ifood",
        },
        accessToken,
        logout
      )
        .then((value) => {
          setCheckoutListIfood(value);
          setFirstSearch(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [accessToken, refreshList, logout]);

  useEffect(() => {
    if (accessToken !== "") {
      apiPost(
        "/romaneioCheckoutSummary",
        {
          dbName: "xama_dev",
          ops: "muve",
        },
        accessToken,
        logout
      )
        .then((value) => {
          setCheckoutListMuve(value);
          setFirstSearch(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [accessToken, refreshList, logout]);

  useEffect(() => {
    setInterval(() => {
      setRefreshList((v) => !v);
    }, 20000);
  }, []);

  return (
    <>
      <Box mt="80px" marginBottom="30px">
        <BoxesRowIfood array={checkoutListIfood} refresFun={setRefreshList} />
      </Box>
      {checkoutListIfood.length === 0 && firstSearch === true && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="30px"
        >
          <Header
            title="Não há Checkouts de Ifood para serem finalizados"
            subtitle=""
          />
        </Box>
      )}

      <Box>
        <hr
          style={{
            border: "dotted 1px",
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
          }}
        ></hr>
      </Box>

      <Box mt="80px" marginBottom="30px">
        <BoxesRowMuve array={checkoutListMuve} refresFun={setRefreshList} />
      </Box>

      {checkoutListMuve.length === 0 && firstSearch === true && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="30px"
        >
          <Header
            title="Não há Checkouts de Muve para serem finalizados"
            subtitle=""
          />
        </Box>
      )}

      <Box>
        <hr
          style={{
            border: "dotted 2px",
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
          }}
        ></hr>
      </Box>
    </>
  );
};

export default XamaCheckout;
