import { configureStore } from "@reduxjs/toolkit";
import { auth0Slice } from "./auth0";

const store = configureStore({
  reducer: {
    auth0: auth0Slice.reducer,
  },
});

export default store;
