import { Box, useTheme } from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { colorTokens } from "../../theme";
import customToolbarMaker from "./CustomTableToolbar";

const Table = (props) => {
  const theme = useTheme();
  const colors = colorTokens(theme.palette.mode);

  return (
    <Box
      m="5px 0 0 0 "
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: theme.palette.secondary.main,
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme.palette.primary.dark,
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: theme.palette.primary.light,
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: theme.palette.primary.dark,
        },
        "& .MuiCheckbox-root": {
          color: `${theme.palette.secondary.main} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${theme.palette.neutral.light} !important`,
        },
      }}
    >
      <DataGrid
        autoHeight
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={props.jsonData}
        checkboxSelection={props.checkBoxSelection}
        onRowSelectionModelChange={(id) => {
          const selectedIDs = new Set(id);
          const selectedRowData = props.jsonData.filter((row) => {
            return selectedIDs.has(row.id);
          });
          if (props.checkBoxSelection) {
            props.selectionFun(selectedRowData);
          }
        }}
        columns={props.columns}
        columnVisibilityModel={{
          // Hide columns status and traderName, the other columns will remain visible
          id: false,
        }}
        components={{
          Toolbar: customToolbarMaker(props.jsonData, `${props.fileName}`),
        }}
        componentsProps={{
          basePopper: {
            sx: {
              "& .MuiDataGrid-menu": {
                background: `${theme.palette.primary.light} !important`,
              },

              "& .MuiPaper-root": {
                background: `${theme.palette.primary.light} !important`,
              },

              "& .MuiDataGrid-menu .MuiPaper-root": {
                background: `${theme.palette.primary.light} !important`,
              },

              "& .MuiButtonBase-root": {
                color: `${theme.palette.neutral.light} !important`,
              },

              "& .MuiSwitch-switchBase": {
                color: `${theme.palette.neutral.light} !important`,
                background: `transparent !important`,
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default Table;
