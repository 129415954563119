import Header from "../../global/layoutComponents/Header";
import {
  Box,
  useTheme,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutIf401 } from "../../global/utilFunctions/logoutIf400";
import { prettyNumber } from "../../global/utilFunctions/mathUtils";
import { CheckoutStatBox } from "../../global/boxComponents/CheckoutStatBox";
import StatBox from "../../global/boxComponents/StatBox";
import axios from "axios";
import PaidIcon from "@mui/icons-material/Paid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PeopleIcon from "@mui/icons-material/People";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { apiPost } from "../../global/utilFunctions/apiRequests";
const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, confirmDisabled } = props;

  const theme = useTheme();

  const listSX = {
    backgroundColor: theme.palette.primary.main,
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog" sx={listSX}>
        {title}
      </DialogTitle>
      <DialogContent sx={listSX}>{children}</DialogContent>
      <DialogActions sx={listSX}>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          sx={{
            color: theme.palette.neutral.light,
            backgroundColor: theme.palette.danger.main,
          }}
        >
          Não!
        </Button>
        <Button
          variant="contained"
          disabled={confirmDisabled}
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          sx={{
            color: theme.palette.neutral.light,
            backgroundColor: theme.palette.primary.dark,
          }}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getRomaneioSummary = (apiToken, logout, filterDate) => {
  try {
    const token = apiToken;
    if (token != null) {
      const data = axios
        .post(
          process.env.REACT_APP_DASHBOARD_API + "/romaneioDay",
          {
            filterDate: filterDate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          logoutIf401(err, logout, token);
        });

      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

const getRomaneioCheckoutSummary = (apiToken, logout, filterDate) => {
  try {
    const token = apiToken;
    if (token != null) {
      const data = axios
        .post(
          process.env.REACT_APP_DASHBOARD_API + "/romaneioCheckoutSummary",
          {
            filterDate: filterDate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data.data[0];
        })
        .catch((err) => {
          logoutIf401(err, logout, token);
          return {};
        });

      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

const getRomaneioCheckoutValid = (apiToken, logout, filterDate) => {
  try {
    const token = apiToken;
    if (token != null) {
      const data = axios
        .post(
          process.env.REACT_APP_DASHBOARD_API + "/romaneioCheckoutValid",
          {
            filterDate: filterDate,
            ops: "rondolog",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          logoutIf401(err, logout, token);
          return {};
        });

      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

const transformRomaneioCheckoutSummary = (summary) => {
  if (summary.numero_viagens == 0) {
    return null;
  } else {
    return summary;
  }
};

const transformRomaneio = (romaneioSummary, date) => {
  const dateMaker = (shift) => {
    const today = new Date();
    today.setDate(today.getDate() + shift);
    return today.toISOString().slice(0, 10);
  };
  const nullDayMaker = (date) => {
    if (date == null) {
      return {
        totalValue: "0",
        date: dateMaker(0),
      };
    } else {
      return {
        totalValue: "0",
        date: date,
      };
    }
  };

  const completeDayMaker = (romaneioSummary, date) => {
    const elementFound = romaneioSummary.find((e) => e.date == date);

    if (elementFound == undefined) {
      return nullDayMaker(date);
    }
    return {
      totalValue: elementFound.total,
      date: elementFound.date,
    };
  };

  if (romaneioSummary == null) {
    return nullDayMaker(date);
  } else {
    const newRomaneioSummary = completeDayMaker(romaneioSummary, date);

    return newRomaneioSummary;
  }
};

const confirmCheckout = (apiToken, logout, payload) => {
  try {
    const token = apiToken;
    if (token != null) {
      const data = axios
        .post(
          process.env.REACT_APP_DASHBOARD_API + "/romaneioCheckoutSubmmit",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data.data);
          return null;
        })
        .catch((err) => {
          logoutIf401(err, logout, token);
          return {};
        });

      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

const CheckoutRomaneioCte = () => {
  const theme = useTheme();
  const datetime = new Date().toISOString().slice(0, 10);
  const { logout } = useAuth0();
  const accessToken = useSelector((state) => state.auth0.accessToken);
  const user = useSelector((state) => state.auth0.user);

  const [romaneioCheckoutSummary, setRomaneioCheckoutSummary] = useState(null);
  const [romaneioSummary, setRomaneioSummary] = useState(null);
  const [datePicked, setDatePicker] = useState(dayjs(datetime));
  const [checkoutDateValid, setCheckoutDateValid] = useState(false);
  const [cteAvailable, setCteAvailable] = useState({ valor_disponivel_cte: 0 });

  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const newRomaneioSummary = transformRomaneio(
    romaneioSummary,
    datePicked.format("YYYY-MM-DD")
  );

  useEffect(() => {
    if ((cteAvailable != null) & (newRomaneioSummary != null)) {
      if (
        BigInt(cteAvailable.valor_disponivel_cte) <
        BigInt(newRomaneioSummary.totalValue)
      ) {
        setConfirmDisabled(true);
      } else {
        setConfirmDisabled(false);
      }
    }
  }, [cteAvailable, newRomaneioSummary]);

  useEffect(() => {
    if ((datePicked != null) & (accessToken != "")) {
      getRomaneioSummary(
        accessToken,
        logout,
        datePicked.format("YYYY-MM-DD")
      ).then((value) => {
        setRomaneioSummary(value);
      });
    }
  }, [accessToken, datePicked]);

  useEffect(() => {
    if ((datePicked != null) & (accessToken != "")) {
      getRomaneioCheckoutValid(
        accessToken,
        logout,
        datePicked.format("YYYY-MM-DD")
      ).then((value) => {
        setCheckoutDateValid(value);
      });
    }
  }, [accessToken, datePicked]);

  useEffect(() => {
    if (accessToken != "") {
      apiPost(
        "/cteAvailable",
        { filterDate: datePicked },
        accessToken,
        logout
      ).then((value) => {
        if (value[0].valor_disponivel_cte == null) {
          setCteAvailable({ valor_disponivel_cte: 0 });
        } else {
          setCteAvailable(value[0]);
        }
      });
    }
  }, [accessToken, datePicked]);

  useEffect(() => {
    if ((datePicked != null) & (accessToken != "")) {
      getRomaneioCheckoutSummary(
        accessToken,
        logout,
        datePicked.format("YYYY-MM-DD")
      ).then((value) => {
        setRomaneioCheckoutSummary(transformRomaneioCheckoutSummary(value));
      });
    }
  }, [accessToken, datePicked]);

  return (
    <Box m="20px">
      {/* Row com DatePICKER */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
        gridAutoRows="0"
        position
        gap="20px"
        mt="60px"
        marginBottom="40px"
      >
        <Box
          gridColumn={`span 6`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Header title="Checkout" subtitle="Aprovação dos romaneios" />
          </Box>
        </Box>

        <Box
          gridColumn={`span 6`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            sx={{ minWidth: "100px", width: "50%" }}
          >
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={dayjs(datetime)}
                minDate={dayjs(datetime).subtract(45, "days")}
                maxDate={dayjs(datetime).add(1, "days")}
                sx={{ marginBottom: "30px" }}
                label={"Data para verificar"}
                onChange={(newValue) => {
                  setCheckoutDateValid(false);
                  setRomaneioCheckoutSummary(null);
                  setDatePicker(newValue);
                }}
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Box>

      {/* GRID SYSTEM */}
      {/* Day Zero */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
        gridAutoRows="140px"
        position
        gap="20px"
        marginBottom="2px"
      >
        {
          <StatBox
            span="6"
            BigNumber={newRomaneioSummary.totalValue}
            BigNumberType="cents"
            title={`Total Agregado do dia ${newRomaneioSummary.date}`}
            percNumber=""
            icon={
              <PaidIcon
                sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
              />
            }
          />
        }
        {
          <StatBox
            span="6"
            BigNumber={cteAvailable.valor_disponivel_cte}
            BigNumberType="cents"
            title="Valor total em CTE disponível"
            percNumber=""
            icon={
              <ReceiptLongIcon
                sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
              />
            }
          />
        }
      </Box>

      {romaneioCheckoutSummary && (
        <Box textAlign="center" mt="30px" marginBottom="30px">
          <hr
            style={{
              border: "dotted 2px",
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.main,
            }}
          ></hr>
        </Box>
      )}

      {/* Row com dados de Checkout */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
        gridAutoRows="140px"
        position
        gap="20px"
        marginBottom="2px"
      >
        {romaneioCheckoutSummary && (
          <CheckoutStatBox
            span="6"
            BigNumber={romaneioCheckoutSummary.numero_viagens}
            BigNumberType="integer"
            title={`Total de número de viagens:`}
            icon={
              <LocalShippingIcon
                sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
              />
            }
          />
        )}
        {romaneioCheckoutSummary && (
          <CheckoutStatBox
            span="6"
            BigNumber={romaneioCheckoutSummary.agregados_unicos}
            BigNumberType="integer"
            title={`Contagem de Agregados únicos:`}
            icon={
              <PeopleIcon
                sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
              />
            }
          />
        )}
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
        gridAutoRows="140px"
        position
        gap="20px"
        marginBottom="2px"
      >
        {romaneioCheckoutSummary && (
          <CheckoutStatBox
            span="6"
            BigNumber={romaneioCheckoutSummary.total_valor_face}
            BigNumberType="cents"
            title={`Valor de face acumulado: `}
            icon={
              <PaidIcon
                sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
              />
            }
          />
        )}
        {romaneioCheckoutSummary && (
          <CheckoutStatBox
            span="6"
            BigNumber={romaneioCheckoutSummary.avg}
            BigNumberType="cents"
            title={`Valor de face médio por Agregado:`}
            icon={
              <PaidIcon
                sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
              />
            }
          />
        )}
      </Box>

      {romaneioCheckoutSummary && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
          gridAutoRows="140px"
          position
          gap="20px"
          marginBottom="2px"
        >
          <Box gridColumn={`span 6`} />

          <Box gridColumn={`span 6`}>
            <Box width="100%" marginTop="30px" marginLeft="30px">
              <Fab
                color="primary"
                aria-label="add"
                disabled={!checkoutDateValid}
                onClick={() => setConfirmOpen(true)}
              >
                <ShoppingCartCheckoutIcon />
              </Fab>
              <ConfirmDialog
                title=""
                confirmDisabled={confirmDisabled}
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={() => {
                  setCheckoutDateValid(false);
                  confirmCheckout(accessToken, logout, {
                    checkoutDate: datePicked.format("YYYY-MM-DD"),
                    userInfo: { email: user.email },
                  });
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{
                    color: theme.palette.neutral.light,
                    marginBottom: "10px",
                  }}
                >
                  Confirmação
                </Typography>

                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ color: theme.palette.neutral.light }}
                >
                  {!confirmDisabled &&
                    `Autoriza o checkout do dia ${datePicked.format(
                      "YYYY-MM-DD"
                    )} de valor total de ${prettyNumber(
                      newRomaneioSummary.totalValue,
                      "cents"
                    )}?`}

                  {confirmDisabled &&
                    `Para o dia ${datePicked.format(
                      "YYYY-MM-DD"
                    )} existem menos CT-e disponíveis (${prettyNumber(
                      cteAvailable.valor_disponivel_cte,
                      "cents"
                    )}) que Romaneios (${prettyNumber(
                      newRomaneioSummary.totalValue,
                      "cents"
                    )})`}
                </Typography>
              </ConfirmDialog>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CheckoutRomaneioCte;
