import Header from "../../global/layoutComponents/Header";
import {
  Box,
  useTheme,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutIf401 } from "../../global/utilFunctions/logoutIf400";
import { prettyNumber } from "../../global/utilFunctions/mathUtils";
import { CheckoutStatBox } from "../../global/boxComponents/CheckoutStatBox";
import StatBox from "../../global/boxComponents/StatBox";
import axios from "axios";
import PaidIcon from "@mui/icons-material/Paid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DownloadIcon from "@mui/icons-material/Download";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { apiGet, apiPost } from "../../global/utilFunctions/apiRequests";
const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, confirmDisabled } = props;

  const theme = useTheme();

  const listSX = {
    backgroundColor: theme.palette.primary.main,
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog" sx={listSX}>
        {title}
      </DialogTitle>
      <DialogContent sx={listSX}>{children}</DialogContent>
      <DialogActions sx={listSX}>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          sx={{
            color: theme.palette.neutral.light,
            backgroundColor: theme.palette.danger.main,
          }}
        >
          Não!
        </Button>
        <Button
          variant="contained"
          disabled={confirmDisabled}
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          sx={{
            color: theme.palette.neutral.light,
            backgroundColor: theme.palette.primary.dark,
          }}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const downloadPayment = (filterDate, type, accessToken, logout) => {
  apiPost(
    "/romaneioTEDDownload",
    { filterDate: filterDate, type: type },
    accessToken,
    logout
  ).then((value) => {
    console.log(value);
    const url = window.URL.createObjectURL(new Blob([value.url]));
    const link = document.createElement("a");
    link.href = value.url;
    link.setAttribute("download", value.fileName);
    document.body.appendChild(link);
    link.click();
  });
};

const RondologPaymentVerify = () => {
  const theme = useTheme();
  const datetime = new Date().toISOString().slice(0, 10);
  const { logout } = useAuth0();
  const accessToken = useSelector((state) => state.auth0.accessToken);
  const user = useSelector((state) => state.auth0.user);

  const [romaneioPaymentSummary, setRomaneioPaymentSummary] = useState(null);
  const [datePicked, setDatePicker] = useState(dayjs(datetime));

  const [downloadValid, setDownloadValid] = useState(false);

  const [currentTime, setCurrentTime] = useState(null);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmOpenNL, setConfirmOpenNL] = useState(false);

  useEffect(() => {
    if ((datePicked != null) & (accessToken != "")) {
      apiGet("/currentTime", accessToken, logout).then((value) => {
        if (value != null) {
          const d = new Date(value.currenttime);
          setCurrentTime(d);
        }
      });
    }
  }, [accessToken, datePicked]);

  useEffect(() => {
    if ((datePicked != null) & (accessToken != "")) {
      apiGet("/currentTime", accessToken, logout).then((value) => {
        if (value != null) {
          const d = new Date(value.currenttime);
          setCurrentTime(d);
        }
      });
    }
  }, [accessToken, datePicked]);

  useEffect(() => {
    if (currentTime != null) {
      const pickedDate = new Date(datePicked.format("YYYY-MM-DD"));
      if (
        (currentTime.getUTCHours() >= 19) |
        ((currentTime.getUTCDay() > pickedDate.getUTCDay()) &
          (currentTime.getUTCMonth() >= pickedDate.getUTCMonth()))
      ) {
        setDownloadValid(true);
      }
    }
  }, [accessToken, datePicked, currentTime]);

  useEffect(() => {
    if ((datePicked != null) & (accessToken != "")) {
      apiPost(
        "/romaneioPaymentSummary",
        { filterDate: datePicked.format("YYYY-MM-DD") },
        accessToken,
        logout
      ).then((value) => {
        setRomaneioPaymentSummary(value);
      });
    }
  }, [accessToken, datePicked]);

  return (
    <Box m="20px">
      {/* Row com DatePICKER */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
        gridAutoRows="0"
        position
        gap="20px"
        mt="60px"
        marginBottom="40px"
      >
        <Box
          gridColumn={`span 6`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Header title="TED" subtitle="TEDs aprovadas e não aprovadas" />
          </Box>
        </Box>

        <Box
          gridColumn={`span 6`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            sx={{ minWidth: "100px", width: "50%" }}
          >
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={dayjs(datetime)}
                minDate={dayjs(datetime).subtract(30, "days")}
                maxDate={dayjs(datetime).add(1, "days")}
                sx={{ marginBottom: "30px" }}
                label={"Data para verificar"}
                onChange={(newValue) => {
                  setConfirmDisabled(false);
                  setRomaneioPaymentSummary(null);
                  setDatePicker(newValue);
                }}
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Box>

      {/* GRID SYSTEM */}
      {/* Row com TED LIQUIDADA ou Concluida */}

      {romaneioPaymentSummary && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
          gridAutoRows="140px"
          position
          gap="20px"
          marginBottom="2px"
        >
          {
            <StatBox
              span="6"
              BigNumber={
                romaneioPaymentSummary.tedLiquidado.numero_ted_liquidado
              }
              BigNumberType="integer"
              title={`Total TEDs liquidados`}
              percNumber=""
              icon={
                <ReceiptLongIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }
          {
            <StatBox
              span="6"
              BigNumber={romaneioPaymentSummary.tedLiquidado.acc_valor_compra}
              BigNumberType="cents"
              title="Valor total de TEDs realizadas (Valor de Compra)"
              percNumber=""
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }
        </Box>
      )}

      {romaneioPaymentSummary && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
          gridAutoRows="140px"
          position
          gap="20px"
          marginBottom="2px"
        >
          {
            <StatBox
              span="6"
              BigNumber={romaneioPaymentSummary.tedLiquidado.acc_valor_desagio}
              BigNumberType="integer"
              title={`Total valor de Desagio efetuado`}
              percNumber=""
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }
          {
            <StatBox
              span="6"
              BigNumber={romaneioPaymentSummary.tedLiquidado.acc_valor_face}
              BigNumberType="cents"
              title="Total de valor de Face"
              percNumber=""
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }
        </Box>
      )}

      {/* Botão de Ação */}
      {romaneioPaymentSummary && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
          gridAutoRows="140px"
          position
          gap="20px"
          marginBottom="2px"
        >
          <Box gridColumn={`span 6`} />

          <Box gridColumn={`span 6`}>
            <Box width="100%" marginTop="30px" marginLeft="30px">
              <Fab
                color="primary"
                aria-label="add"
                disabled={!downloadValid}
                onClick={() => setConfirmOpen(true)}
              >
                <DownloadIcon />
              </Fab>
              <ConfirmDialog
                title=""
                confirmDisabled={confirmDisabled}
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={() => {
                  downloadPayment(
                    datePicked.format("YYYY-MM-DD"),
                    "liquidado",
                    accessToken,
                    logout
                  );
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{
                    color: theme.palette.neutral.light,
                    marginBottom: "10px",
                  }}
                >
                  Download
                </Typography>

                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ color: theme.palette.neutral.light }}
                >
                  {!confirmDisabled &&
                    `Download dos pagamentos confirmados para o dia ${datePicked.format(
                      "YYYY-MM-DD"
                    )}`}

                  {confirmDisabled && ``}
                </Typography>
              </ConfirmDialog>
            </Box>
          </Box>
        </Box>
      )}

      {romaneioPaymentSummary && (
        <Box textAlign="center" mt="30px" marginBottom="30px">
          <hr
            style={{
              border: "dotted 2px",
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.main,
            }}
          ></hr>
        </Box>
      )}

      {/* Row com TED NAO LIQUIDADA */}
      {romaneioPaymentSummary && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
          gridAutoRows="140px"
          position
          gap="20px"
          marginBottom="2px"
        >
          {
            <StatBox
              span="6"
              BigNumber={
                romaneioPaymentSummary.tedNaoLiquidado.numero_ted_nao_liquidado
              }
              BigNumberType="integer"
              title={`Total TEDs NÃO liquidadas`}
              percNumber=""
              icon={
                <ReceiptLongIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }
          {
            <StatBox
              span="6"
              BigNumber={
                romaneioPaymentSummary.tedNaoLiquidado.acc_valor_compra
              }
              BigNumberType="cents"
              title="Valor total de TEDs NÃO realizadas (Valor de Compra)"
              percNumber=""
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }
        </Box>
      )}

      {romaneioPaymentSummary && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
          gridAutoRows="140px"
          position
          gap="20px"
          marginBottom="2px"
        >
          {
            <StatBox
              span="6"
              BigNumber={
                romaneioPaymentSummary.tedNaoLiquidado.acc_valor_desagio
              }
              BigNumberType="integer"
              title={`Total valor de Desagio NÃO efetuado`}
              percNumber=""
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }
          {
            <StatBox
              span="6"
              BigNumber={romaneioPaymentSummary.tedNaoLiquidado.acc_valor_face}
              BigNumberType="cents"
              title="Total de valor de Face das NÃO efetuadas"
              percNumber=""
              icon={
                <PaidIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: "26px" }}
                />
              }
            />
          }
        </Box>
      )}

      {/* Botão de Ação */}
      {romaneioPaymentSummary && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
          gridAutoRows="140px"
          position
          gap="20px"
          marginBottom="2px"
        >
          <Box gridColumn={`span 6`} />

          <Box gridColumn={`span 6`}>
            <Box width="100%" marginTop="30px" marginLeft="30px">
              <Fab
                color="primary"
                aria-label="add"
                disabled={!downloadValid}
                onClick={() => setConfirmOpenNL(true)}
              >
                <DownloadIcon />
              </Fab>
              <ConfirmDialog
                title=""
                confirmDisabled={confirmDisabled}
                open={confirmOpenNL}
                setOpen={setConfirmOpenNL}
                onConfirm={() => {
                  downloadPayment(
                    datePicked.format("YYYY-MM-DD"),
                    "naoLiquidado",
                    accessToken,
                    logout
                  );
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{
                    color: theme.palette.neutral.light,
                    marginBottom: "10px",
                  }}
                >
                  Download
                </Typography>

                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ color: theme.palette.neutral.light }}
                >
                  {!confirmDisabled &&
                    `Download dos pagamentos NÃO efetuados para o dia ${datePicked.format(
                      "YYYY-MM-DD"
                    )}`}

                  {confirmDisabled && ``}
                </Typography>
              </ConfirmDialog>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RondologPaymentVerify;
