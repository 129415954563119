import Plot from "react-plotly.js";
import { Box, useTheme, Paper } from "@mui/material";

const LinePercentChart = ({ data, xTitle, yTitle }) => {
	const theme = useTheme();

	const layout = {
		autosize: true,
		font: { family: "Sans-serif", size: 14, color: "#333333" },
		hovermode: "x",
		hoverlabel: {
			bgcolor: "#f0f0f0",
			bordercolor: "#cccccc",
			font: { color: "#333333" },
		},
		xaxis: {
			title: xTitle,
			tickangle: "auto",
			type: "date",
			tickformat: "%Y-%m-%d",
			fixedrange: false,
		},
		yaxis: {
			title: {
				text: yTitle,
				standoff: 200,
			},
			tickformat: ".2%", 
			fixedrange: false,
			range: [0, 1], 
		},
		legend: {
			orientation: "h",
		},
		plot_bgcolor: "#ffffff",
		paper_bgcolor: "#ffffff",
		margin: {
			l: 80,  
			r: 20,
			t: 10,
			b: 80, 
		},
	};

	return (
		<Paper
			backgroundcolor={theme.palette.primary.light}
			display="flex"
			alignitems="center"
			justifycontent="center"
			elevation={20}
			sx={{ bgcolor: "white", height: "80%", padding: 0, margin: 0 }} 
		>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				sx={{
					width: "100%",
					height: "75vh", 
					padding: 0, 
					margin: 0, 
				}}
			>
				<Plot
					data={data}
					layout={layout}
					useResizeHandler
					style={{ width: "100%", height: "80%" }}
					config={{ displayModeBar: false }}
				/>
			</Box>
		</Paper>
	);
};

export default LinePercentChart;
