import Header from "../../global/layoutComponents/Header";
import { CheckoutStatBox } from "../../global/boxComponents/CheckoutStatBox";
import { Box, useTheme, Typography, Fab } from "@mui/material";
import { SelectForm } from "../../global/inputComponents/SelectForm";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { ConfirmDialog } from "../../global/inputComponents/ConfirmDialog";
import PaidIcon from "@mui/icons-material/Paid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleIcon from "@mui/icons-material/People";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import StorageIcon from "@mui/icons-material/Storage";
import { apiPost } from "../../global/utilFunctions/apiRequests";

const ConfirmationFab = (props) => {
	const theme = useTheme();
	const { disabled, icon, confirmMsg, confirmFun } = props;
	const [confirmOpen, setConfirmOpen] = useState(false);

	return (
		<Box display="flex" sx={{ m: 1, position: "relative" }}>
			<Fab color="primary" aria-label="add" disabled={disabled} onClick={() => setConfirmOpen(true)}>
				{icon}
			</Fab>
			<ConfirmDialog title="" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={confirmFun}>
				<Typography
					variant="h3"
					fontWeight="bold"
					sx={{
						color: theme.palette.neutral.light,
						marginBottom: "10px",
					}}
				>
					Confirmação
				</Typography>

				<Typography variant="h3" fontWeight="bold" sx={{ color: theme.palette.neutral.light }}>
					{confirmMsg}
				</Typography>
			</ConfirmDialog>
		</Box>
	);
};

const StatBoxCheckoutResolution = ({
	span,
	title,
	subtitle,
	refresFun,
	wtsDisabled,
	docDisabled,
	insertDbDisabled,
	dbName,
	filterDate,
}) => {
	const theme = useTheme();
	const accessToken = useSelector((state) => state.auth0.accessToken);
	const user = useSelector((state) => state.auth0.user);
	const { logout } = useAuth0();

	return (
		<Box
			gridColumn={`span ${span}`}
			backgroundColor={theme.palette.primary.light}
			display="flex"
			alignItems="center"
			justifyContent="center"
			sx={{ boxShadow: 1, minWidth: 300 }}
		>
			<Box width="100%" m="0 30px">
				<Box display="flex" justifyContent="space-between">
					<Box>
						<Typography variant="h3" fontWeight="bold" sx={{ color: theme.palette.neutral.light }}>
							{title}
						</Typography>
						<Typography variant="h3" fontWeight="bold" sx={{ color: theme.palette.secondary.main }}>
							{subtitle}
						</Typography>
					</Box>

					<Box display="flex" sx={{ "& > :not(style)": { m: 0, mr: 0.2 } }}>
						<ConfirmationFab
							disabled={wtsDisabled}
							icon={<WhatsAppIcon />}
							confirmMsg="Mandar mensagens?"
							confirmFun={() => {
								const resp = apiPost(
									"/gestorConfirmCheckout",
									{
										dbName: dbName,
										filterDate: filterDate,
										userInfo: {
											email: user.email,
										},
									},
									accessToken,
									logout
								);

								const timeout = setTimeout(() => {
									refresFun((e) => !e);
								}, 3000);
								const timeout2 = setTimeout(() => {
									refresFun((e) => !e);
								}, 2000);
								const timeout3 = setTimeout(() => {
									refresFun((e) => !e);
								}, 1000);
							}}
						/>
						<ConfirmationFab
							disabled={docDisabled}
							icon={<ShoppingCartCheckoutIcon />}
							confirmMsg="Realizar compra?"
							confirmFun={() => {
								const resp = apiPost(
									"/gestorDocStart",
									{
										dbName: dbName,
										filterDate: filterDate,
										userInfo: {
											email: user.email,
										},
									},
									accessToken,
									logout
								);

								console.log(resp.data);

								const timeout = setTimeout(() => {
									refresFun((e) => !e);
								}, 3000);
								const timeout2 = setTimeout(() => {
									refresFun((e) => !e);
								}, 2000);
								const timeout3 = setTimeout(() => {
									refresFun((e) => !e);
								}, 1000);
							}}
						/>

						<ConfirmationFab
							disabled={insertDbDisabled}
							icon={<StorageIcon />}
							confirmMsg="Inserir a compra no DB?"
							confirmFun={() => {
								const resp = apiPost(
									"/gestorSubmitRemessaDb",
									{
										dbName: dbName,
										filterDate: filterDate,
										userInfo: {
											email: user.email,
										},
									},
									accessToken,
									logout
								);
								const timeout = setTimeout(() => {
									refresFun((e) => !e);
								}, 3000);
								const timeout2 = setTimeout(() => {
									refresFun((e) => !e);
								}, 2000);
								const timeout3 = setTimeout(() => {
									refresFun((e) => !e);
								}, 1000);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const CheckoutResolutionBoxes = (props) => {
	const { array, dbName, refresFun } = props;
	const theme = useTheme();

	return array.map((e) => {
		return (
			<Box key={`${e.date_to_checkout}${e.avg}${e.total_valor_face}`}>
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					gridAutoRows="140px"
					position
					gap="20px"
					marginBottom="2px"
				>
					{
						<CheckoutStatBox
							span="3"
							BigNumber={e.numero_viagens}
							BigNumberType="integer"
							title={`Total de número de viagens`}
							icon={<LocalShippingIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
					{
						<CheckoutStatBox
							span="3"
							BigNumber={e.agregados_unicos}
							BigNumberType="integer"
							title={`Agregados únicos`}
							icon={<PeopleIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<CheckoutStatBox
							span="3"
							BigNumber={e.total_valor_face}
							BigNumberType="cents"
							title={`Valor de face acumulado`}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}

					{
						<StatBoxCheckoutResolution
							span="3"
							subtitle={"CHECKOUT"}
							refresFun={refresFun}
							wtsDisabled={(e.status == "confirmed") | ((e.status == "closed") | (e.status == "committed"))}
							docDisabled={(e.status == "requested") | ((e.status == "closed") | (e.status == "committed"))}
							insertDbDisabled={(e.status == "requested") | ((e.status == "confirmed") | (e.status == "committed"))}
							title={`${e.date_to_checkout.slice(0, 10)}`}
							dbName={dbName}
							filterDate={`${e.date_to_checkout.slice(0, 10)}`}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
				</Box>
			</Box>
		);
	});
};

export default function CheckoutRondologViewer(props) {
	const theme = useTheme();

	const [firstSearch, setFirstSearch] = useState(false);
	const [checkoutList, setCheckoutList] = useState([]);
	const [refreshList, setRefreshList] = useState(true);

	useEffect(() => {
		if ((props.fundName != "") & (props.accessToken != "")) {
			apiPost(
				"/gestorCheckoutList",
				{
					dbName: props.fundName,
				},
				props.accessToken,
				props.logout
			)
				.then((value) => {
					setCheckoutList(value);
					setFirstSearch(true);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [props.accessToken, props.fundName, refreshList, props.logout]);

	useEffect(() => {
		setInterval(() => {
			setRefreshList((v) => !v);
		}, 20000);
	}, []);

	return (
		<>
			<Box mt="80px" marginBottom="30px">
				<CheckoutResolutionBoxes array={checkoutList} dbName={props.fundName} refresFun={setRefreshList} />
			</Box>

			{checkoutList.length === 0 && props.fundName !== "" && firstSearch === true && (
				<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
					<Header title="Não há Checkouts para serem finalizados dos últimos 20 dias" subtitle="" />
				</Box>
			)}

			<Box>
				<hr
					style={{
						border: "dotted 2px",
						color: theme.palette.secondary.main,
						backgroundColor: theme.palette.primary.main,
					}}
				></hr>
			</Box>
		</>
	);
}
